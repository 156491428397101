import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { User } from '../../models/user';

interface UsuariosFormModalProps {
  user?: User | null;
  handleChangePassword: (password: string) => void;
  onClose: () => void;
}

const ModalSenha: React.FC<UsuariosFormModalProps> = ({ user, handleChangePassword, onClose }) => {
  const formik = useFormik<Partial<User> & { passwordConfirm: string } >({
    initialValues: {
      id: user?.id ?? 0,
      password: user?.password ?? '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object<User>({
      password: Yup.string()
        .required('A senha é obrigatória')
        .min(8, 'A senha deve ter pelo menos 8 caracteres'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
        .required('A confirmação da senha é obrigatória'),
    }),
    onSubmit: (values) => {
      handleChangePassword(values.password);
      onClose();
    },
  });

  return (
    <Dialog open={true} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Alterar a senha</DialogTitle>
        <DialogContent>
          {/* Adiciona um texto falando sobre a regras da senha */}
          <Stack spacing={1} sx={{ mb: 3 }}>
            <p>A senha deve ter pelo menos 8 caracteres</p>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          <FormControl fullWidth margin="dense">
              <TextField
                id="#password"
                name={`password-${Math.random()}`}
                label="Senha"
                type="password"
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                value={formik.values.password}
                onChange={(event) => {
                  formik.setFieldValue('password', event.target.value);
                  formik.setFieldTouched('password', true);
                }}
                onFocus={() => formik.setFieldTouched("password", true, false)} // Garante que o campo é marcado como tocado quando focado
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={(formik.touched.password && formik.errors.password) ? formik.errors.password : "A senha deve conter pelo menos 8 caracteres"}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                label="Confirme a Senha"
                type="password"
                fullWidth
                variant="outlined"
                value={formik.values.passwordConfirm}
                onChange={formik.handleChange}
                error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
          <Button type="submit">Alterar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalSenha;
