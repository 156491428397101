import { Servico, ServicoExtended } from '../models/servico'
import {  http } from './client'

export class ServicosService {
  static async getAll (): Promise<Servico[]> {
    try {
      const response = await http.request<Servico[]>({
        url: '/servicos',
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao buscar orientadores')
    }
  }

  static async getToAddOrientador(orientadorId: number): Promise<ServicoExtended[]> {
    try {
      const response = await http.request<ServicoExtended[]>({
        url: `/orientador/servicos-to-add/${orientadorId}`,
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao buscar servicos para orientador')
    }
  }

  static async create(data: FormData): Promise<void> {
    try {
      await http.request({
        url: '/servicos',
        method: 'POST',
        body: data
      })
    } catch (error) {
      throw new Error('Falha ao criar serviço')
    }
  }

  static async update(id: number, data: FormData): Promise<void> {
    try {
      await http.request({
        url: `/servicos/${id}`,
        method: 'PATCH',
        body: data
      })
    } catch (error) {
      throw new Error('Falha ao atualizar serviço')
    }
  }

  static async updateData(id: number, servico: Partial<Servico>): Promise<void> {
    try {
      await http.request({
        url: `/servicos/${id}`,
        method: 'PUT',
        body: servico
      })
    } catch (error) {
      throw new Error('Falha ao atualizar serviço')
    }
  }

  static async remove(id: number): Promise<void> {
    try {
      await http.request({
        url: `/servicos/${id}`,
        method: 'DELETE'
      })
    } catch (error) {
      throw new Error('Falha ao remover serviço')
    }
  }
}
