import {  httpBackend } from './client'


export class AuthService {
  static async login (email: string, password: string) {
    try {
      const response = await httpBackend.request({
        url: '/auth/admin/login',
        method: 'POST',
        body: { email, password }
      })
      return response.body // Deve retornar o token JWT
    } catch (error) {
      throw new Error('Falha ao fazer login')
    }
  }
}
