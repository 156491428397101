import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
} from "@mui/material";
import { DataGrid, GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import Tooltip from "@mui/material/Tooltip";

import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

import AgendamentosFormModal from "./AgendamentosFormModal";
import { AgendamentoExtended } from "../../models/agendamento";
import moment from "moment";
import { AgendamentosService } from "../../services/agendamentos.service";
import { Orientador } from "../../models/orientador";
import { User } from "../../models/user";
import { ConfigService } from "../../services/config.service";
import { Paginated } from "../../models/paginated";
import { DatePicker } from "@mui/x-date-pickers";
import { OrientadoresService } from "../../services/orientadores.service";
import { useBackdrop } from "../../providers/Backdrop";
import { useDebounce } from "../../hooks";
import ConfirmationDialog from "../../components/ConfirmDialog";
import { useSnackbar } from '../../providers/SnackBarProvider'

const CrudDataGrid: React.FC = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selected, setSelected] = useState<AgendamentoExtended | null>(null);
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [rows, setRows] = useState<AgendamentoExtended[]>([]);
  const [paging, setPaging] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [modalReagendarOpen, setModaReagendarlOpen] = useState(false);
  const [order, setOrder] = useState<Paginated["order"]>([]);
  const [query, setQuery] = useState("");
  const [dataInicio, setDataInicioFiltro] = useState(null);
  const [orientadorId, setOrientador] = useState("");
  const [orientadores, setOrientadores] = useState<Orientador[]>([]);
  const [openCofirmFinalizar, setOpenCofirmFinalizar] = useState(false);
  const [openCofirmCancelar, setOpenCofirmCancelar] = useState(false);

  const clearHorarioInicioFiltro = () => setDataInicioFiltro(null);
  const clearQuery = () => setQuery("");

  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const debouncedQuery = useDebounce(query, 700); // Debounce query input

  const fetchRows = useCallback(async () => {
    openBackdrop();
    try {
      const response = await AgendamentosService.getAll({
        page: paging.page,
        pageSize: paging.pageSize,
        filters: { dataInicio, orientadorId },
        query: debouncedQuery,
        order,
      });
      setRows(response.data);
      setRowCount(response.rowCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      closeBackdrop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, dataInicio, debouncedQuery, order, orientadorId]); // Include debouncedQuery in dependencies

  // Consolidated useEffect
  useEffect(() => {
    fetchRows();
    const fetchOrientadores = async () => {
      try {
        const response = await OrientadoresService.getAll();
        setOrientadores(response);
      } catch (error) {
        console.error("Error fetching orientadores:", error);
      }
    };
    fetchOrientadores();
  }, [fetchRows]); // Only one useEffect that triggers fetchRows and fetchOrientadores

  const handleSelectRow = (id: number) => {
    setSelectedId(selectedId === id ? null : id);
  };

  const handleOpenModal = (data?: AgendamentoExtended) => {
    setSelected(data);
    setModaReagendarlOpen(true);
  };

  const handleCloseModal = () => {
    setModaReagendarlOpen(false);
    setSelected(null);
  };

  const handleSaveReagendar = async (agendamentoId: number, novaData: Date, novaHora: string) => {
    openBackdrop()
    try {
      await AgendamentosService.reagendar(agendamentoId, novaData, novaHora);
      openSnackbar("Reagendamento realizado com sucesso", 'success');
      await fetchRows();
    } catch (error) {
      console.log("Error saving reagendamento:", error);
    } finally {
      handleCloseModal();
    }
    closeBackdrop()
  };

  const getTelefone = (user: User) => {
    if (!user?.phone || !user?.phone.area_code || !user?.phone.number)
      return "N/A";
    const { area_code, number } = user.phone;
    return `${area_code} ${number}`;
  };

  const handleSendWhatsapp = async (row: AgendamentoExtended) => {
    openBackdrop();
    const telefone = getTelefone(row.users);
    if (telefone === "N/A") return;
    const mensagem = "Olá, tudo bem?";
    const link = await ConfigService.makeLinkWhatsapp({ telefone, mensagem });
    window.open(link, "_blank");
    closeBackdrop();
  };

  const handleIniciarAtendimento = async (row: AgendamentoExtended) => {
    try {
      setLoadingButtons(true);
      if (row.meetUrl) {
        window.open(row.meetUrl, "_blank");
      } else {
      }
      // Atualiza a lista de agendamentos após iniciar um;
      setLoadingButtons(false);
    } catch (error) {
      console.error("Error starting the appointment:", error);
      setLoadingButtons(false);
    }
  };

  const handleFinalizarAtendimento = async (id: number) => {
    try {
      setLoadingButtons(true);
      await AgendamentosService.finalizar(id);
      await fetchRows();
      setLoadingButtons(false);
    } catch (error) {
      console.error("Error finalizing the appointment:", error);
      setLoadingButtons(false);
    }
  };
  const handleCancelar = async (id: number) => {
    openBackdrop();
    try {
      setLoadingButtons(true);
      await AgendamentosService.cancelar(id);
      await fetchRows();
      setLoadingButtons(false);
      setOpenCofirmCancelar(false);
    } catch (error) {
      console.error("Error canceling the appointment:", error);
      setLoadingButtons(false);
      setOpenCofirmCancelar(false);
    }
    closeBackdrop();
  };

  const columns: GridColDef<AgendamentoExtended>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "servicoId",
      headerName: "Serviço",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (servicoId, row) => {
        if (row.servicos.id === servicoId) return row.servicos.nome;
        return "N/A";
      },
    },
    {
      field: "data",
      headerName: "Data",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) =>
        `${moment(row.dataInicio).format("DD/MM/YYYY")}`,
    },
    {
      // tá nesse formato, horarioFim: "10:00:00" TIME no mysql
      field: "horario",
      headerName: "Horário",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        // Preciso remover os segundos mas não posso usar o moment
        const [horaInicio, minutoInicio] = row.horarioInicio.split(':');
        const [horaFim, minutoFim] = row.horarioFim.split(':');
        return `${horaInicio}:${minutoInicio} - ${horaFim}:${minutoFim}`;
      }
    },
    {
      field: "user_id",
      headerName: "Cliente",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (userId, row) => row.users.id,
      valueFormatter: (userId, row) => {
        if (row.users.id === userId)
          return `${row.users.nome} ${row.users.sobrenome}`;
        return "N/A";
      },
    },
    {
      field: "orientadorId",
      headerName: "Orientador",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (orientadorId, row) => {
        if (row.orientadores.id === orientadorId) return row.orientadores.nome;
        return "N/A";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        switch (row.status) {
          case "approved":
            return "Aprovado";
          case "canceled":
            return "Cancelado";
          case "finished":
            return "Finalizado";
          case "pending":
            return "Pendente";
          case "rejected":
            return "Rejeitado";
          case "rescheduled":
            return "Reagendado";
          default:
            return "N/A";
        }
      },
    },
    {
      field: "actions",
      headerName: "Opções",
      type: "actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          disabled={loadingButtons}
          icon={
            <Tooltip title="Iniciar Atendimento">
              <PlayArrowIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelected(params.row);
            handleIniciarAtendimento(params.row)
          }}
          label="Play"
        />,
        <GridActionsCellItem
          disabled={loadingButtons}
          icon={
            <Tooltip title="Finalizar atendimento">
              <StopCircleIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedId(params.row.id);
            setOpenCofirmFinalizar(true)
          }}
          label="Finalizar atendimento"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Mensagem no Whatsapp">
              <WhatsAppIcon />
            </Tooltip>
          }
          onClick={() => handleSendWhatsapp(params.row)}
          label="Mensagem no Whatsapp"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Reagendar">
              <QueryBuilderIcon />
            </Tooltip>
          }
          onClick={() => handleOpenModal(params.row)}
          label="Reagendar"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Cancelar">
              <CancelIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedId(params.row.id);
            setOpenCofirmCancelar(true)
          }}
          label="Cancelar"
        />,
      ],
    },
  ];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Coluna em telas pequenas, linha em telas maiores
          alignItems: "center",
          justifyContent: "space-between",
          my: 1,
          mx: 1,
        }}
      >
        <FormControl
          fullWidth
          margin="normal"
          sx={{ mr: 2, mb: 2, width: { md: "30%" } }}
        >
          <DatePicker
            label="Data do Agendamento"
            value={dataInicio}
            onChange={(value) => {
              console.log("onChangeData", value);
              setDataInicioFiltro(value);
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => clearHorarioInicioFiltro(),
              },
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          sx={{ mr: 2, mb: 2, width: { md: "40%" } }}
        >
          <TextField
            fullWidth
            id="query"
            label="Consultar"
            variant="outlined"
            value={query}
            onChange={(e) => {
              console.log("onChangeQuery", e.target.value);
              setQuery(e.target.value);
            }}
            InputProps={{
              endAdornment: query ? (
                <Tooltip title="Limpar campo de consulta">
                  <Box sx={{ cursor: "pointer" }} onClick={() => clearQuery()}>
                    <ClearIcon />
                  </Box>
                </Tooltip>
              ) : null,
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          sx={{ width: { md: "30%" }, mb: 2 }}
        >
          <InputLabel id="orientador-select-label">Orientador</InputLabel>
          <Select
            labelId="orientador-select-label"
            id="orientador-select"
            value={orientadorId}
            onChange={(event) => {
              console.log("onChangeOrientador", event.target.value);
              setOrientador(event.target.value);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Altura máxima do menu em pixels
                  overflow: "auto", // Adiciona scroll automaticamente se necessário
                },
              },
            }}
          >
            <MenuItem value="">
              <em>Nenhum</em>
            </MenuItem>
            {orientadores.map((orientador) => (
              <MenuItem key={orientador.id} value={orientador.id}>
                {orientador.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* preciso de um incon button pra fazer fectch dos dados */}
        <IconButton
          sx={{ ml: 1 }}
          size="large"
          onClick={() => {
            console.log("onClickBuscar");
            fetchRows();
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", mx: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onPaginationModelChange={(params) => {
            console.log(params);
            setPaging({ page: params.page + 1, pageSize: params.pageSize }); // DataGrid page is 0-indexed
          }}
          onFilterModelChange={(params) => console.log(params)}
          onSortModelChange={(params) => setOrder(params as any)}
          pageSizeOptions={[10, 20, 50]}
          initialState={{
            pagination: {
              paginationModel: {
                page: paging.page,
                pageSize: paging.pageSize,
              },
            },
          }}
          onRowClick={(params) => handleSelectRow(params.id as number)}
          rowCount={rowCount}
          // loading={loading}
          pagination
          paginationMode="server"
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      {modalReagendarOpen && (
        <AgendamentosFormModal
          data={selected}
          onSave={handleSaveReagendar}
          onClose={handleCloseModal}
        />
      )}
      <ConfirmationDialog
        open={openCofirmFinalizar}
        onClose={() => setOpenCofirmFinalizar(false)}
        onConfirm={() => handleFinalizarAtendimento(selectedId)}
        title="Confirmar finalização de atendimento"
        description="Tem certeza de que deseja finalizar este atendimento?"
      />
      <ConfirmationDialog
        open={openCofirmCancelar}
        onClose={() => setOpenCofirmCancelar(false)}
        onConfirm={() => handleCancelar(selectedId)}
        title="Confirmar Cancelamento"
        description="Tem certeza de que deseja cancelar este atendimento? esta ação não pode ser desfeita."
      />
    </div>
  );
};

export default CrudDataGrid;
