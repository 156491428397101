import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { ServicoExtended } from '../../../models/servico';
import { ServicosService } from '../../../services/servicos.service';
import { NewServicoOrientadorDto } from '../dto/new-servico-orientador.dto';

interface ModalPrecoProps {
  servico: ServicoExtended;
  orientadorId: number;
  novo: boolean;
  onSave: (data: NewServicoOrientadorDto) => void;
  onClose: () => void;
}

const ModalPreco: React.FC<ModalPrecoProps> = ({ servico, orientadorId, onSave, onClose, novo }) => {
  const [servicos, setServicos] = React.useState<ServicoExtended[]>([]);

  const formik = useFormik({
    initialValues: {
      servicoId: +servico?.id || 0,
      preco: servico?.preco || 0
    },
    validationSchema: Yup.object({
      servicoId: Yup.number().required('O serviço é obrigatório'),
      preco: Yup.number().min(0, 'O preço não pode ser negativo').required('O preço é obrigatório')
    }),
    onSubmit: values => {
      onSave({
        orientadorId,
        servicoId: values.servicoId,
        preco: values.preco,
        novo
      });
    },
  });

  const fetchServicos = useCallback(async () => {
    try {
      const response = await ServicosService.getToAddOrientador(orientadorId);
      setServicos(response);
    } catch (error) {
      console.error("Error fetching orientadores:", error);
    }
  }, []);

  useEffect(() => {
    if (novo) fetchServicos();
  }, []);

  return (
    <Dialog open={true} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Novo Preço de Serviço</DialogTitle>
        <DialogContent>
          {
            servicos && novo &&
            <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
              <InputLabel id="servico-select-label">Serviço</InputLabel>
              <Select
                fullWidth
                id="servicoId"
                name="servicoId"
                labelId="servico-select-label"
                label="Serviço"
                value={formik.values.servicoId}
                onChange={formik.handleChange}
                error={formik.touched.servicoId && Boolean(formik.errors.servicoId)}
                margin="dense"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,  // Altura máxima do menu em pixels
                      overflow: 'auto',  // Adiciona scroll automaticamente se necessário
                    },
                  },
                }}
              >
                <MenuItem value=""><em>Nenhum</em></MenuItem>
                {servicos.map(servico => (
                  <MenuItem key={servico.id} value={servico.id}>{servico.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          <TextField
            fullWidth
            id="preco"
            name="preco"
            label="Preço"
            type="number"
            value={formik.values.preco}
            onChange={formik.handleChange}
            error={formik.touched.preco && Boolean(formik.errors.preco)}
            helperText={formik.touched.preco && formik.errors.preco}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
          <Button type="submit" color="primary">Salvar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ModalPreco;
