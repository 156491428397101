import React from 'react'
import { TabContext, TabPanel } from '@mui/lab';
import { FormInfos } from './FormInfos'
import { Box, Paper, Tab, Tabs } from '@mui/material'
import DiasBloquaedos from './DiasBloqueados'

export const InfosPage: React.FC = () => {
  const [tab, setTab] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
return (
  <>
    <TabContext value={tab}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleChange} aria-label="">
          <Tab label="Dados Gerais" value="1"/>
          <Tab label="Dias Bloqueados" value="2"/>
        </Tabs>
      </Box>
      <TabPanel value="1" sx={{ p: 0, m: 0, mt: 1 }}>
        <FormInfos />
      </TabPanel>
      <TabPanel value="2" sx={{ p: 0, m: 0, mt: 1}}>
        <Paper elevation={1} style={{ padding: 16 }} sx={{ m: 2 }}>
          <DiasBloquaedos />
        </Paper>
      </TabPanel>
    </Box>
  </TabContext>

  </>
)
}

