import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { ConfigService } from "../../services/config.service";
import { Infos } from "../../models/infos";
import { TextMaskCustom } from "../../components/TextFieldMasked";
import DiasBloquaedos from "./DiasBloqueados";

// Schema de validação do Yup
const validationSchema = Yup.object({
  telefone: Yup.string().required("O telefone é obrigatório"),
  email: Yup.string().email("Email inválido").required("O email é obrigatório"),
  whatsappMessage: Yup.string().required(
    "A mensagem do WhatsApp é obrigatória"
  ),
  facebook: Yup.string().url("URL inválida"),
  instagram: Yup.string().url("URL inválida"),
  youtube: Yup.string().url("URL inválida"),
  tiktok: Yup.string().url("URL inválida"),
  videoApresentacao: Yup.string().url("URL inválida"),
  horarioAtendimentoInicio: Yup.string().required(
    "O horário de início é obrigatório"
  ),
  horarioAtendimentoFim: Yup.string().required(
    "O horário de fim é obrigatório"
  ),
});

export const FormInfos: React.FC = () => {
  const [infos, setInfos] = useState<Infos>();
  const [loading, setLoading] = useState(true);

  const formik = useFormik<Infos>({
    initialValues: {
      telefone: "",
      email: "",
      whatsappMessage: "",
      facebook: "",
      instagram: "",
      youtube: "",
      tiktok: "",
      videoApresentacao: "",
      horarioAtendimentoInicio: "",
      horarioAtendimentoFim: "",
      diasAtendimento: [
        { diaSemana: 0, nomeDiaSemana: "Domingo", ativo: false },
        { diaSemana: 1, nomeDiaSemana: "Segunda-feira", ativo: false },
        { diaSemana: 2, nomeDiaSemana: "Terça-feira", ativo: false },
        { diaSemana: 3, nomeDiaSemana: "Quarta-feira", ativo: false },
        { diaSemana: 4, nomeDiaSemana: "Quinta-feira", ativo: false },
        { diaSemana: 5, nomeDiaSemana: "Sexta-feira", ativo: false },
        { diaSemana: 6, nomeDiaSemana: "Sábado", ativo: false },
      ],
    },
    validationSchema,
    onSubmit: async (values) => {
      await ConfigService.update(values);
      await fetchInfos();
      console.log(values);
    },
  });

  const fetchInfos = useCallback(async () => {
    setLoading(true);
    const response = await ConfigService.getInfos();
    setInfos(response);
    formik.setValues({
      telefone: response.telefone || "",
      email: response.email || "",
      whatsappMessage: response.whatsappMessage || "",
      facebook: response.facebook || "",
      instagram: response.instagram || "",
      youtube: response.youtube || "",
      tiktok: response.tiktok || "",
      videoApresentacao: response.videoApresentacao || "",
      horarioAtendimentoInicio: response.horarioAtendimentoInicio || "",
      horarioAtendimentoFim: response.horarioAtendimentoFim || "",
      diasAtendimento: response.diasAtendimento || [
        { diaSemana: 0, nomeDiaSemana: "Domingo", ativo: false },
        { diaSemana: 1, nomeDiaSemana: "Segunda-feira", ativo: false },
        { diaSemana: 2, nomeDiaSemana: "Terça-feira", ativo: false },
        { diaSemana: 3, nomeDiaSemana: "Quarta-feira", ativo: false },
        { diaSemana: 4, nomeDiaSemana: "Quinta-feira", ativo: false },
        { diaSemana: 5, nomeDiaSemana: "Sexta-feira", ativo: false },
        { diaSemana: 6, nomeDiaSemana: "Sábado", ativo: false },
      ],
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchInfos();
  }, [fetchInfos]);

  const handleSwitchChange = (index) => {
    const newDiasAtendimento = formik.values.diasAtendimento.map((day, idx) =>
      idx === index ? { ...day, ativo: !day.ativo } : day
    );
    formik.setFieldValue("diasAtendimento", newDiasAtendimento);
  };

  if (!infos || loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Paper elevation={1} style={{ padding: 16, marginBottom: 16 }}>
            <Typography variant="h6" gutterBottom>
              Contatos e Redes Sociais
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Telefone"
                  name="telefone"
                  value={formik.values.telefone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.telefone && Boolean(formik.errors.telefone)
                  }
                  helperText={formik.touched.telefone && formik.errors.telefone}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Facebook"
                  name="facebook"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.facebook && Boolean(formik.errors.facebook)
                  }
                  helperText={formik.touched.facebook && formik.errors.facebook}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Instagram"
                  name="instagram"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.instagram && Boolean(formik.errors.instagram)
                  }
                  helperText={
                    formik.touched.instagram && formik.errors.instagram
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="YouTube"
                  name="youtube"
                  value={formik.values.youtube}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.youtube && Boolean(formik.errors.youtube)
                  }
                  helperText={formik.touched.youtube && formik.errors.youtube}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="TikTok"
                  name="tiktok"
                  value={formik.values.tiktok}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.tiktok && Boolean(formik.errors.tiktok)}
                  helperText={formik.touched.tiktok && formik.errors.tiktok}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="WhatsApp Message"
                  name="whatsappMessage"
                  value={formik.values.whatsappMessage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.whatsappMessage &&
                    Boolean(formik.errors.whatsappMessage)
                  }
                  helperText={
                    formik.touched.whatsappMessage &&
                    formik.errors.whatsappMessage
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Vídeo de Apresentação"
                  name="videoApresentacao"
                  value={formik.values.videoApresentacao}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.videoApresentacao &&
                    Boolean(formik.errors.videoApresentacao)
                  }
                  helperText={
                    formik.touched.videoApresentacao &&
                    formik.errors.videoApresentacao
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={1} style={{ padding: 16 }}>
            <Typography variant="h6" gutterBottom>
              Horários de Atendimento
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.horarioAtendimentoInicio &&
                    Boolean(formik.errors.horarioAtendimentoInicio)
                  }
                  margin="dense"
                >
                  <TextField
                    fullWidth
                    error={
                      formik.touched.horarioAtendimentoInicio &&
                      Boolean(formik.errors.horarioAtendimentoInicio)
                    }
                    margin="dense"
                    label="Início Expediente"
                    variant="outlined"
                    value={formik.values.horarioAtendimentoInicio}
                    onChange={formik.handleChange}
                    name="horarioAtendimentoInicio"
                    id="inicio-expediente"
                    InputProps={{
                      inputComponent: TextMaskCustom as any,
                      inputProps: {
                        mask: "00:00",
                      },
                    }}
                    helperText={
                      formik.touched.horarioAtendimentoInicio &&
                      formik.errors.horarioAtendimentoInicio
                        ? formik.errors.horarioAtendimentoInicio
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.horarioAtendimentoFim &&
                    Boolean(formik.errors.horarioAtendimentoFim)
                  }
                  margin="dense"
                >
                  <TextField
                    fullWidth
                    error={
                      formik.touched.horarioAtendimentoFim &&
                      Boolean(formik.errors.horarioAtendimentoFim)
                    }
                    margin="dense"
                    label="Término Expediente"
                    variant="outlined"
                    value={formik.values.horarioAtendimentoFim}
                    onChange={formik.handleChange}
                    name="horarioAtendimentoFim"
                    id="fim-expediente"
                    InputProps={{
                      inputComponent: TextMaskCustom as any,
                      inputProps: {
                        mask: "00:00",
                      },
                    }}
                    helperText={
                      formik.touched.horarioAtendimentoFim &&
                      formik.errors.horarioAtendimentoFim
                        ? formik.errors.horarioAtendimentoFim
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row", // Alinha todos os itens em uma linha
                  justifyContent: "space-between", // Distribui o espaço uniformemente
                  alignItems: "center", // Alinha os itens verticalmente no centro
                  p: 2, // Padding ao redor do Box
                  margin: "0 auto", // Centraliza o Box horizontalmente
                }}
              >
                {formik.values.diasAtendimento.map((day, index) => (
                  <FormGroup key={index}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={day.ativo}
                          onChange={() => handleSwitchChange(index)}
                          name={`diasAtendimento[${index}].work`}
                        />
                      }
                      label={day.nomeDiaSemana}
                    />
                  </FormGroup>
                ))}
              </Box>
            </Grid>
          </Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Centraliza horizontalmente
              marginTop: 2, // Adiciona espaço acima do botão
              marginBottom: 2, // Adiciona espaço abaixo do botão
            }}
          >
            <Button variant="contained" color="primary" type="submit">
              Salvar Alterações
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};
