import React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'
import { YoutubeVideo } from '../../models/videos'

interface VideosProps {
  onSave: (data: YoutubeVideo) => void;
  video?: YoutubeVideo;
  onClose: () => void;
}

const VideosFormModal: React.FC<VideosProps> = ({
  video,
  onClose,
  onSave,
}) => {
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const formik = useFormik<YoutubeVideo>({
    initialValues: {
      id: video?.id ?? null,
      title: video?.title ?? "",
      link: video?.link ?? "",
      vertical: video?.vertical ?? false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Título é obrigatório"),
      link: Yup.string().required("Link é obrigatório"),
      vertical: Yup.boolean().optional(),
    }),
    onSubmit: (values) => {
      openBackdrop();
      try {
        onSave(values);
        onClose();
      } catch (error) {
        openSnackbar('Erro ao salvar serviço', 'error');
      }
      closeBackdrop();
    },
  });

  const handleChangeYoutubeLink = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    try {
      const link = new URL(value);
      const videoId = link.searchParams.get('v');

      if (videoId) {
        formik.setFieldValue("link", `https://www.youtube.com/embed/${videoId}`);
      } else {
        formik.setFieldValue("link", value);
      }
    } catch (error) {
      formik.setFieldValue("link", value);
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {video?.id ? "Editar Video" : "Novo Video"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
              <TextField
              multiline
              margin="dense"
              id="video"
              name="link"
              label="Link Youtube"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.link}
              onChange={handleChangeYoutubeLink}
              error={formik.touched.link && Boolean(formik.errors.link)}
              helperText={formik.touched.link && formik.errors.link}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Título"
              name="title" // Alterado de "testemunho" para "title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.vertical}
                  onChange={(event) => formik.setFieldValue("vertical", event.target.checked)}
                  name="vertical"
                  color="primary"
                />
              }
              label="Vídeo vertical (Shorts)?"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
          <Button type="submit">Salvar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VideosFormModal;
