import { User } from '../models/user'
import { http } from './client'

export class UserService {
  static async getAll(): Promise<User[]> {
    try {
      const response = await http.request({
        url: '/users',
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao buscar usuários')
    }
  }

  static async getById(id: number): Promise<User> {
    try {
      const response = await http.request({
        url: `/users/${id}`,
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao buscar usuário')
    }
  }

  static async create(data: User): Promise<User> {
    try {
      const response = await http.request({
        url: '/users',
        method: 'POST',
        body: data
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao criar usuário')
    }
  }

  static async update(id: number, data: Partial<User>): Promise<User> {
    try {
      const response = await http.request({
        url: `/users/${id}`,
        method: 'PATCH',
        body: data
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao atualizar usuário')
    }
  }

  static async remove(id: number): Promise<void> {
    try {
      await http.request({
        url: `/users/${id}`,
        method: 'DELETE'
      })
    } catch (error) {
      throw new Error('Falha ao remover usuário')
    }
  }
}
