import { Pagamento } from '../models/pagamentos'
import { PagedData, Paginated } from '../models/paginated'
import {  http } from './client'

export class PagamentoService {
  static async getPagamentos (data: Paginated): Promise<PagedData<Pagamento>>  {
    try {
      const response = await http.request({
        url: '/pagamentos/completo',
        method: 'POST',
        body: data
      })
      return response.body // Deve retornar o token JWT
    } catch (error) {
      throw new Error('Falha ao fazer login')
    }
  }
}
