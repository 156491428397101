import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'
import ConfirmationDialog from '../../components/ConfirmDialog'
import { VideosService } from '../../services/videos.service'
import { YoutubeVideo } from '../../models/videos'
import VideosFormModal from './VideosFormModal'

const VideosDataGrid: React.FC = () => {
  const [selected, setSelected] = useState<YoutubeVideo | null>(null);
  const [rows, setRows] = useState<YoutubeVideo[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();
  const [openCofirmation, setOpenCofirmation] = useState(false);

  const fetchRows = useCallback(async () => {
    try {
      openBackdrop();
      const response = await VideosService.getAll() ?? [];
      setRows(response);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
    closeBackdrop();
  }, []);

  useEffect(() => {
    fetchRows();
  }, [fetchRows]);

  const handleSave = async (data: YoutubeVideo) => {
    openBackdrop();
    if (!data.id) {
      await VideosService.create(data);
      openSnackbar("Serviço criado com sucesso", "success");
    } else {
      await VideosService.update(data.id, data);
      openSnackbar("Serviço atualizado com sucesso", "success");
    }
    await fetchRows();
    closeBackdrop();
  }

  const handleRemover = async (video: YoutubeVideo) => {
    try {
      openBackdrop();
      await VideosService.remove(video.id);
      await fetchRows();
      openSnackbar("Serviço removido com sucesso", "success");
    } catch (error) {
      console.error("Error removing serviço:", error);
      openSnackbar("Erro ao remover serviço", "error");
    }
    setOpenCofirmation(false)
    closeBackdrop();
  }

  const columns: GridColDef<YoutubeVideo>[] = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "link", headerName: "Link", width: 380 },
    { field: "title", headerName: "Título", width: 450 },
    {
      field: "actions",
      headerName: "Opções",
      type: "actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelected(params.row as YoutubeVideo)
            setModalOpen(true)
          }}
          label="Editar"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover">
              <DeleteIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelected(params.row as YoutubeVideo)
            setOpenCofirmation(true)
          }}
          label="Remover"
        />,
      ],
    },
  ];

  return (
    <>
      <Button sx={{ m: 1 }} onClick={() =>  {
        setSelected(null)
        setModalOpen(true)
      }}>
        Adicionar Novo Vídeo
      </Button>
      <Box sx={{ display: "flex", mx: 1, mt: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onRowClick={(params) => setSelected(params.row as YoutubeVideo)}
          // loading={loading}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      {modalOpen && (
        <VideosFormModal
          onSave={handleSave}
          onClose={() => setModalOpen(false)}
          video={selected}
        />
      )}
      <ConfirmationDialog
        open={openCofirmation}
        onClose={() => setOpenCofirmation(false)}
        onConfirm={() => handleRemover(selected)}
        title="Confirmar remoção"
        description="Tem certeza de que deseja remover este vídeo? Esta ação é irreversível."
      />
    </>
  );
};

export default VideosDataGrid;
