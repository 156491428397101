import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert } from '@mui/material';

type SnackbarContextType = {
  openSnackbar: (msg: string, severityType?: 'success' | 'info' | 'warning' | 'error') => void;
  closeSnackbar: () => void;
};

const defaultSnackbarContext: SnackbarContextType = {
  openSnackbar: () => {}, // Função vazia como placeholder
  closeSnackbar: () => {}
};

const SnackbarContext = createContext<SnackbarContextType>(defaultSnackbarContext);

type SnackbarProviderProps = {
  children: ReactNode;
};

// Provedor do contexto
export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('info');

  const openSnackbar = (msg: string, severityType: 'success' | 'info' | 'warning' | 'error' = 'info') => {
    setMessage(msg);
    setSeverity(severityType);
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      <Snackbar
        open={open}
        autoHideDuration={1000 * 5} // 10 Segundos
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

// Hook para usar o snackbar
export const useSnackbar = () => useContext(SnackbarContext);
