import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosProgressEvent,
} from "axios"
import { HttpClientInterface } from "./interfaces"
import { HttpRequest, HttpResponse, HttpStatusCode, RequestInterceptor, ResponseInterceptor } from "./models"

export class HttpClient implements HttpClientInterface {
  private client: AxiosInstance

  constructor(config?: AxiosRequestConfig, requestInterceptor?: RequestInterceptor, responseInterceptor?: ResponseInterceptor) {
    this.client = axios.create(config)

    // Adicionando um interceptor de requisição
    if (requestInterceptor) {
      console.log('Foi1')
      this.client.interceptors.request.use(requestInterceptor)
    }

    if (responseInterceptor) {
      console.log('Foi2')
      this.client.interceptors.response.use(responseInterceptor)
    }
  }

  async request<Res = any, Req = any> (
    data: HttpRequest<Req>,
  ): Promise<HttpResponse<Res>> {
    let response: AxiosResponse<Res>
    let err

    try {
      response = await this.client.request({
        url: data.url,
        method: data.method,
        params: data.params,
        data: data.body as Req,
        headers: data.headers,
        timeout: data.timeout,
      })
    } catch (error) {
      response = error as AxiosResponse<Res>
      err = error
    }
    return {
      statusCode: response?.status ?? HttpStatusCode.timeout,
      body: response?.data,
      headers: response?.headers ?? (err as AxiosResponse<Req>).config.headers,
      statusText: response?.statusText,
    }
  }

  async uploadFile (method: 'put' | 'post' | 'patch', url: string, file: File, contentType: string, progressCallback: (percentage: number) => void): Promise<HttpResponse<any>> {
    const response = await this.client({
      method: method,
      url: url,
      data: file,
      headers: {
        "Content-Type": contentType,
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        progressCallback(percentCompleted)
      },
    })

    return {
      statusCode: response.status,
      body: response.data,
      headers: response.headers,
      statusText: response.statusText,
    }
  }
}
