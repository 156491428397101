import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,

} from "@mui/material";
import { Orientador } from "../../../models/orientador";
import FormOrientador, { FormikFormHandler } from "./FormOrientador";
import { TabContext, TabPanel } from '@mui/lab';
import ServicosOrientador from "./ServicosOrientador";


interface OrientadoresFormModalProps {
  orientador?: Orientador | null;
  onSubmit: (orientador: Partial<Orientador>) => void;
  onClose: () => void;
}

const OrientadoresFormModal: React.FC<OrientadoresFormModalProps> = ({
  orientador,
  onSubmit,
  onClose,
}) => {
  const [tab, setTab] = React.useState('1');
  const formRef = useRef<FormikFormHandler>(null);

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();  // Formik's submitForm method
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

 return (
    <Dialog open={true} onClose={onClose}>
      {!orientador && <DialogTitle>Novo Orientador</DialogTitle>}
      <DialogContent>
        <>
          { !orientador &&
            <>
              <FormOrientador ref={formRef} onSubmit={onSubmit} orientador={orientador} />
              <DialogActions>
                <Button onClick={onClose}>Fechar</Button>
                <Button onClick={handleOnSubmit}>Salvar</Button>
              </DialogActions>
            </>
          }
          { orientador &&
            <TabContext value={tab}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Orientador" value="1"/>
                    <Tab label="Serviços" value="2"/>
                  </Tabs>
                </Box>
                <TabPanel value="1" sx={{ p: 0, m: 0, mt: 1 }}>
                  <FormOrientador ref={formRef} onSubmit={onSubmit} orientador={orientador} />
                  <DialogActions>
                    <Button onClick={onClose}>Fechar</Button>
                    <Button onClick={handleOnSubmit}>Salvar</Button>
                  </DialogActions>
                </TabPanel>
                <TabPanel value="2" sx={{ p: 0, m: 0, mt: 1}}>
                  <ServicosOrientador orientadorId={orientador.id} />
                </TabPanel>
              </Box>
            </TabContext>
          }
        </>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button onClick={handleOnSubmit}>Salvar</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default OrientadoresFormModal;
