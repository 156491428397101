import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link as RouterLink } from 'react-router-dom';

export function NotFoundPage() {
  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ErrorOutlineIcon style={{ fontSize: 120, color: 'red' }} />
      <Typography variant="h4" component="h1" gutterBottom>
        404: Página não encontrada
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Ops! A página que você está procurando não existe.
      </Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" component={RouterLink} to="/">
          Voltar para a página inicial
        </Button>
      </Box>
    </Container>
  );
}
