import React from "react";
import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import { Orientador } from "../../../models/orientador";
import * as Yup from "yup";

interface FormOrientadorProps {
  orientador: Orientador;
  onSubmit: (orientador: Orientador) => void;
}

export interface FormikFormHandler {
  submitForm: () => void;
}

const FormOrientador = React.forwardRef<FormikFormHandler, FormOrientadorProps>(({
  orientador,
  onSubmit,
}, ref) => {
  const formik = useFormik({
    initialValues: {
      id: orientador?.id || 0,
      nome: orientador?.nome || "",
      apresentacao: orientador?.apresentacao || "",
      descricaoLonga: orientador?.descricaoLonga || "",
      headline: orientador?.headline || "",
      linkSocial: orientador?.linkSocial || "",
      video: orientador?.video || "",
    },
    validationSchema: Yup.object<Orientador>({
      nome: Yup.string().required("Nome é obrigatório"),
      headline: Yup.string().required("Destaque é obrigatório"),
      apresentacao: Yup.string().required("Apresentação curta é obrigatória"),
      descricaoLonga: Yup.string().required("Apresentação longa é obrigatória"),
      linkSocial: Yup.string().required("Instagram é obrigatório"),
      video: Yup.string().required("Vídeo é obrigatório"),
    }),
    onSubmit: (values) => {
      onSubmit({
        ...orientador,
        ...values,
      });
    },
  });

  React.useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm,
  }));

  const handleChangeYoutubeLink = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    try {
      const link = new URL(value);
      const videoId = link.searchParams.get('v');

      if (videoId) {
        formik.setFieldValue("video", `https://www.youtube.com/embed/${videoId}`);
      } else {
        formik.setFieldValue("video", value);
      }
    } catch (error) {
      formik.setFieldValue("video", value);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <>
        {
          orientador && <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
              width: "100%",
            }}
          >
            <img
              src={orientador?.imagem}
              alt="Orientador"
              style={{
                maxHeight: 200,
                maxWidth: 300,
                width: "auto",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>
        }
        <TextField
          multiline
          margin="dense"
          id="nome"
          name="nome"
          label="Nome"
          type="text"
          fullWidth
          variant="outlined"
          value={formik.values.nome}
          onChange={formik.handleChange}
          error={formik.touched.nome && Boolean(formik.errors.nome)}
          helperText={formik.touched.nome && formik.errors.nome}
        />
        <TextField
          multiline
          margin="dense"
          id="headline"
          name="headline"
          label="Destaque Carrossel"
          type="text"
          fullWidth
          variant="outlined"
          value={formik.values.headline}
          onChange={formik.handleChange}
          error={formik.touched.headline && Boolean(formik.errors.headline)}
          helperText={formik.touched.headline && formik.errors.headline}
        />
        <TextField
          multiline
          margin="dense"
          id="apresentacao"
          name="apresentacao"
          label="Apresentação Curta"
          type="text"
          fullWidth
          variant="outlined"
          value={formik.values.apresentacao}
          onChange={formik.handleChange}
          error={
            formik.touched.apresentacao && Boolean(formik.errors.apresentacao)
          }
          helperText={formik.touched.apresentacao && formik.errors.apresentacao}
        />
        <TextField
          multiline
          margin="dense"
          id="descricaoLonga"
          name="descricaoLonga"
          label="Apresentação Longa"
          type="text"
          fullWidth
          variant="outlined"
          value={formik.values.descricaoLonga}
          onChange={formik.handleChange}
          error={
            formik.touched.descricaoLonga && Boolean(formik.errors.descricaoLonga)
          }
          helperText={
            formik.touched.descricaoLonga && formik.errors.descricaoLonga
          }
        />
        <TextField
          multiline
          margin="dense"
          id="linkSocial"
          name="linkSocial"
          label="Instagram"
          type="text"
          fullWidth
          variant="outlined"
          value={formik.values.linkSocial}
          onChange={formik.handleChange}
          error={formik.touched.linkSocial && Boolean(formik.errors.linkSocial)}
          helperText={formik.touched.linkSocial && formik.errors.linkSocial}
        />
        <TextField
          multiline
          margin="dense"
          id="video"
          name="video"
          label="Apresentação Youtube"
          type="text"
          fullWidth
          variant="outlined"
          value={formik.values.video}
          onChange={handleChangeYoutubeLink}
          error={formik.touched.video && Boolean(formik.errors.video)}
          helperText={formik.touched.video && formik.errors.video}
        />
      </>
    </form>
  );
});

export default FormOrientador;
