import { Produto } from '../models/produto'
import {  http } from './client'

export class ProdutosService {
  static async getAll (): Promise<Produto[]> {
    try {
      const response = await http.request<Produto[]>({
        url: '/produtos',
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao buscar produtos')
    }
  }

  static async create(data: FormData): Promise<void> {
    try {
      await http.request({
        url: '/produtos',
        method: 'POST',
        body: data
      })
    } catch (error) {
      throw new Error('Falha ao criar serviço')
    }
  }

  static async update(id: number, data: FormData): Promise<void> {
    try {
      await http.request({
        url: `/produtos/${id}`,
        method: 'PATCH',
        body: data
      })
    } catch (error) {
      throw new Error('Falha ao atualizar serviço')
    }
  }

  static async updateData(id: number, produto: Partial<Produto>): Promise<void> {
    try {
      await http.request({
        url: `/produtos/${id}`,
        method: 'PUT',
        body: produto
      })
    } catch (error) {
      throw new Error('Falha ao atualizar serviço')
    }
  }

  static async remove(id: number): Promise<void> {
    try {
      await http.request({
        url: `/produtos/${id}`,
        method: 'DELETE'
      })
    } catch (error) {
      throw new Error('Falha ao remover serviço')
    }
  }
}
