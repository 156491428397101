import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from '@mui/icons-material/Edit';
import { ServicoExtended } from "../../../models/servico";
import { OrientadoresService } from "../../../services/orientadores.service";
import { NewServicoOrientadorDto } from "../dto/new-servico-orientador.dto";
import { useBackdrop } from "../../../providers/Backdrop";
import { useSnackbar } from "../../../providers/SnackBarProvider";
import ModalPreco from './ModalPreco'

interface ServicosOrientadorProps {
  orientadorId: number;
}

interface OpenModalProps {
  novo: boolean;
  servico?: ServicoExtended;
}

const ServicosOrientador: React.FC<ServicosOrientadorProps> = ({ orientadorId }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<ServicoExtended | null>(null);
  const [novo, setNovo] = useState(true);
  const [rows, setRows] = useState<ServicoExtended[]>([]);

  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const fetchServicos = useCallback(async () => {
    try {
      openBackdrop();
      const response = await OrientadoresService.getServicos(orientadorId);
      setRows(response);
    } catch (error) {
      console.error("Error fetching orientadores:", error);
    }
    closeBackdrop();
  }, []);

  useEffect(() => {
    fetchServicos();
  }, [fetchServicos]);

  const handleOpenModal = (data: OpenModalProps) => {
    setNovo(data.novo);
    setSelected(data.servico);
    setOpen(true);
  };

  const handleSave = async (data: NewServicoOrientadorDto) => {
    openBackdrop();
    if (data.novo) {
      await OrientadoresService.adicionarServico(
        data.orientadorId,
        data.servicoId,
        data.preco,
      );
      openSnackbar("Serviço adicionado com sucesso!", "success")
    } else {
      await OrientadoresService.atualizarServico(
        data.orientadorId,
        data.servicoId,
        data.preco,
      );
      openSnackbar("Serviço atualizado com sucesso!", "success")
    }
    closeBackdrop();
    fetchServicos();
    setOpen(false);
  };

  const handleRemover = async (servicoId: number) => {
    openBackdrop();
    await OrientadoresService.removerServico(orientadorId, servicoId);
    openSnackbar("Serviço removido com sucesso!", "success")
    closeBackdrop();
    fetchServicos();
  };

  const columns: GridColDef<ServicoExtended>[] = [
    { field: "nome", headerName: "Nome", width: 200 },
    {
      field: "valor",
      headerName: "Nome",
      width: 130,
      valueGetter: (_, row) => {
        return `R$ ${row.preco.toFixed(2).replace(".", ",")}`;
      },
    },
    {
      field: "actions",
      headerName: "Opções",
      type: "actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => handleOpenModal({ servico: params.row as ServicoExtended, novo: false })}
          label="Editar"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover">
              <CancelIcon />
            </Tooltip>
          }
          onClick={() => handleRemover(params.row.id as number)}
          label="Remover"
        />,
      ],
    },
  ];

  return (
    <>
      <Box>
        <Button onClick={() => handleOpenModal({ novo: true })} sx={{ m: 1 }}>
          Adicionar
        </Button>
        <DataGrid
          autoHeight={true}
          disableRowSelectionOnClick
          rows={rows}
          columns={columns}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      {open && (
        <ModalPreco
          novo={novo}
          orientadorId={orientadorId}
          servico={selected}
          onClose={() => setOpen(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default ServicosOrientador;
