import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'
import ConfirmationDialog from '../../components/ConfirmDialog'
import { Testimonial } from '../../models/testimonials'
import { TestemunhosService } from '../../services/testemunhos.service'
import TestemunhoModal from './TestemunhosFormModal'

const GridTestemunhos: React.FC = () => {
  const [selected, setSelected] = useState<Testimonial | null>(null);
  const [rows, setRows] = useState<Testimonial[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();
  const [openCofirmation, setOpenCofirmation] = useState(false);

  const fetchTestemunhos = useCallback(async () => {
    try {
      openBackdrop();
      const response = await TestemunhosService.getAll();
      setRows(response);
    } catch (error) {
      console.error("Error fetching testemunhos:", error);
    }
    closeBackdrop();
  }, []);

  useEffect(() => {
    fetchTestemunhos();
  }, [fetchTestemunhos]);

  const handleSave = async (data: Testimonial) => {
    openBackdrop();
    if (!data.id) {
      await TestemunhosService.create(data);
      openSnackbar("Serviço criado com sucesso", "success");
    } else {
      await TestemunhosService.update(data.id, data);
      openSnackbar("Serviço atualizado com sucesso", "success");
    }
    await fetchTestemunhos();
    closeBackdrop();
  }

  const handleRemover = async (testemunho: Testimonial) => {
    try {
      openBackdrop();
      await TestemunhosService.remove(testemunho.id);
      await fetchTestemunhos();
      openSnackbar("Serviço removido com sucesso", "success");
    } catch (error) {
      console.error("Error removing serviço:", error);
      openSnackbar("Erro ao remover serviço", "error");
    }
    setOpenCofirmation(false)
    closeBackdrop();
  }

  const columns: GridColDef<Testimonial>[] = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Nome", width: 350 },
    {
      field: "actions",
      headerName: "Opções",
      type: "actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelected(params.row as Testimonial)
            setModalOpen(true)
          }}
          label="Editar"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover">
              <DeleteIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelected(params.row as Testimonial)
            setOpenCofirmation(true)
          }}
          label="Remover"
        />,
      ],
    },
  ];

  return (
    <>
      <Button sx={{ m: 1 }} onClick={() =>  {
        setSelected(null)
        setModalOpen(true)
      }}>
        Adicionar Novo Testemunho
      </Button>
      <Box sx={{ display: "flex", mx: 1, mt: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onRowClick={(params) => setSelected(params.row as Testimonial)}
          // loading={loading}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      {modalOpen && (
        <TestemunhoModal
          onSave={handleSave}
          onClose={() => setModalOpen(false)}
          testimonial={selected}
        />
      )}
      <ConfirmationDialog
        open={openCofirmation}
        onClose={() => setOpenCofirmation(false)}
        onConfirm={() => handleRemover(selected)}
        title="Confirmar remoção"
        description="Tem certeza de que deseja remover este testemunho? Esta ação é irreversível."
      />
    </>
  );
};

export default GridTestemunhos;
