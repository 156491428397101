import { JwtPayload, jwtDecode } from 'jwt-decode';

export const isTokenValid = (token: string | null): boolean => {
  if (!token) {
    return false;
  }

  // Verificação básica de estrutura de token JWT
  const tokenParts = token.split('.');
  if (tokenParts.length !== 3) {
    console.error('Token malformado');
    return false;
  }

  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    if (decodedToken.exp && Date.now() >= decodedToken.exp * 1000) {
      console.error('Token expirado');
      return false;
    }
    return true;
  } catch (error) {
    console.error('Erro ao decodificar o token', error);
    return false;
  }
};
