import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import Tooltip from "@mui/material/Tooltip";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

import { AgendamentoExtended } from "../../models/agendamento";
import moment from "moment";
import { AgendamentosService } from "../../services/agendamentos.service";
import { Orientador } from "../../models/orientador";
import { Paginated } from "../../models/paginated";
import { DatePicker } from "@mui/x-date-pickers";
import { OrientadoresService } from "../../services/orientadores.service";
import { useBackdrop } from "../../providers/Backdrop";
import { useDebounce } from "../../hooks";

const HistoricoDataGrid: React.FC = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [rows, setRows] = useState<AgendamentoExtended[]>([]);
  const [paging, setPaging] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [order, setOrder] = useState<Paginated["order"]>([]);
  const [query, setQuery] = useState("");
  const [horarioInicio, setHorarioInicioFiltro] = useState(null);
  const [orientadorId, setOrientador] = useState("");
  const [orientadores, setOrientadores] = useState<Orientador[]>([]);

  const clearHorarioInicioFiltro = () => setHorarioInicioFiltro(null);
  const clearQuery = () => setQuery("");

  const { openBackdrop, closeBackdrop } = useBackdrop();

  const debouncedQuery = useDebounce(query, 700); // Debounce query input

  const fetchRows = useCallback(async () => {
    openBackdrop();
    try {
      const response = await AgendamentosService.getHistorico({
        page: paging.page,
        pageSize: paging.pageSize,
        filters: { horarioInicio, orientadorId },
        query: debouncedQuery,
        order,
      });
      setRows(response.data);
      setRowCount(response.rowCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      closeBackdrop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, horarioInicio, debouncedQuery, order, orientadorId]); // Include debouncedQuery in dependencies

  // Consolidated useEffect
  useEffect(() => {
    fetchRows();
    const fetchOrientadores = async () => {
      try {
        const response = await OrientadoresService.getAll();
        setOrientadores(response);
      } catch (error) {
        console.error("Error fetching orientadores:", error);
      }
    };
    fetchOrientadores();
  }, [fetchRows]); // Only one useEffect that triggers fetchRows and fetchOrientadores

  const handleSelectRow = (id: number) => {
    setSelectedId(selectedId === id ? null : id);
  };

  const columns: GridColDef<AgendamentoExtended>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "servicoId",
      headerName: "Serviço",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (servicoId, row) => {
        if (row.servicos.id === servicoId) return row.servicos.nome;
        return "N/A";
      },
    },
    {
      field: "data",
      headerName: "Data",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) =>
        `${moment(row.dataInicio).format("DD/MM/YYYY")}`,
    },
    {
      // tá nesse formato, horarioFim: "10:00:00" TIME no mysql
      field: "horario",
      headerName: "Horário",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        // Preciso remover os segundos mas não posso usar o moment
        const [horaInicio, minutoInicio] = row.horarioInicio.split(':');
        const [horaFim, minutoFim] = row.horarioFim.split(':');
        return `${horaInicio}:${minutoInicio} - ${horaFim}:${minutoFim}`;
      }
    },
    {
      field: "user_id",
      headerName: "Cliente",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (userId, row) => row.users.id,
      valueFormatter: (userId, row) => {
        if (row.users.id === userId)
          return `${row.users.nome} ${row.users.sobrenome}`;
        return "N/A";
      },
    },
    {
      field: "orientadorId",
      headerName: "Orientador",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (orientadorId, row) => {
        if (row.orientadores.id === orientadorId) return row.orientadores.nome;
        return "N/A";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        switch (row.status) {
          case "approved":
            return "Aprovado";
          case "canceled":
            return "Cancelado";
          case "finished":
            return "Finalizado";
          case "pending":
            return "Pendente";
          case "rejected":
            return "Rejeitado";
          case "rescheduled":
            return "Reagendado";
          default:
            return "N/A";
        }
      },
    },
    // createdAt: "2024-05-15T06:07:54.000Z"
    {
      field: "createdAt",
      headerName: "Criado em",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        return `${moment(value).format("DD/MM/YYYY HH:mm")}`;
      },
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        return `${moment(value).format("DD/MM/YYYY HH:mm")}`;
      },
    },

  ];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Coluna em telas pequenas, linha em telas maiores
          alignItems: "center",
          justifyContent: "space-between",
          my: 1,
          mx: 1,
        }}
      >
        <FormControl
          fullWidth
          margin="normal"
          sx={{ mr: 2, mb: 2, width: { md: "30%" } }}
        >
          <DatePicker
            label="Data do Agendamento"
            value={horarioInicio}
            onChange={(value) => {
              console.log("onChangeData", value);
              setHorarioInicioFiltro(value);
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => clearHorarioInicioFiltro(),
              },
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          sx={{ mr: 2, mb: 2, width: { md: "40%" } }}
        >
          <TextField
            fullWidth
            id="query"
            label="Consultar"
            variant="outlined"
            value={query}
            onChange={(e) => {
              console.log("onChangeQuery", e.target.value);
              setQuery(e.target.value);
            }}
            InputProps={{
              endAdornment: query ? (
                <Tooltip title="Limpar campo de consulta">
                  <Box sx={{ cursor: "pointer" }} onClick={() => clearQuery()}>
                    <ClearIcon />
                  </Box>
                </Tooltip>
              ) : null,
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          sx={{ width: { md: "30%" }, mb: 2 }}
        >
          <InputLabel id="orientador-select-label">Orientador</InputLabel>
          <Select
            labelId="orientador-select-label"
            id="orientador-select"
            value={orientadorId}
            onChange={(event) => {
              console.log("onChangeOrientador", event.target.value);
              setOrientador(event.target.value);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Altura máxima do menu em pixels
                  overflow: "auto", // Adiciona scroll automaticamente se necessário
                },
              },
            }}
          >
            <MenuItem value="">
              <em>Nenhum</em>
            </MenuItem>
            {orientadores.map((orientador) => (
              <MenuItem key={orientador.id} value={orientador.id}>
                {orientador.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* preciso de um incon button pra fazer fectch dos dados */}
        <IconButton
          sx={{ ml: 1 }}
          size="large"
          onClick={() => {
            console.log("onClickBuscar");
            fetchRows();
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", mx: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onPaginationModelChange={(params) => {
            console.log(params);
            setPaging({ page: params.page + 1, pageSize: params.pageSize }); // DataGrid page is 0-indexed
          }}
          onFilterModelChange={(params) => console.log(params)}
          onSortModelChange={(params) => setOrder(params as any)}
          pageSizeOptions={[10, 20, 50]}
          initialState={{
            pagination: {
              paginationModel: {
                page: paging.page,
                pageSize: paging.pageSize,
              },
            },
          }}
          onRowClick={(params) => handleSelectRow(params.id as number)}
          rowCount={rowCount}
          // loading={loading}
          pagination
          paginationMode="server"
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </div>
  );
};

export default HistoricoDataGrid;
