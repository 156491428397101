import { Testimonial } from '../models/testimonials'
import { http } from './client'

export class TestemunhosService {
  static async getAll() {
    try {
      const response = await http.request({
        url: '/testemunhos',
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao buscar usuários')
    }
  }

  static update(id: number, data: Partial<Testimonial>) {
    return http.request({
      url: `/testemunhos/${id}`,
      method: 'PUT',
      body: data
    })
  }

  static create(data: Testimonial) {
    return http.request({
      url: '/testemunhos',
      method: 'POST',
      body: data
    })
  }

  static remove(id: number) {
    return http.request({
      url: `/testemunhos/${id}`,
      method: 'DELETE'
    })
  }
}
