import { Orientador } from "../models/orientador";
import { ServicoExtended } from "../models/servico";
import { http, httpBackend } from "./client";
import { ConfigService } from "./config.service";

export class OrientadoresService {
  static async getAll(): Promise<Orientador[]> {
    try {
      const response = await http.request<Orientador[]>({
        url: "/admin/orientadores",
        method: "GET",
      });
      return response.body;
    } catch (error) {
      throw new Error("Falha ao buscar orientadores");
    }
  }

  static async update(
    id: number,
    body: Partial<Orientador>,
  ): Promise<Orientador> {
    try {
      const response = await http.request<Orientador>({
        url: `/orientador/${id}`,
        method: "PATCH",
        body,
      });
      return response.body;
    } catch (error) {
      throw new Error("Falha ao atualizar orientador");
    }
  }

  static async create(body: Partial<Orientador>): Promise<Orientador> {
    try {
      const response = await http.request<Orientador>({
        url: "/orientador",
        method: "POST",
        body,
      });
      return response.body;
    } catch (error) {
      throw new Error("Falha ao criar orientador");
    }
  }

  static async uploadImage(file: File, orientadorId: number): Promise<string> {
    try {
      // Pega a URL assinada do S3 para upload
      const signedUrl = await ConfigService.generateSignedUrl(file.name);
      const response = await httpBackend.request<string, File>({
        url: signedUrl,
        method: "PUT",
        body: file,
      });
      // Se deu certo, atualiza o orientador com o link da imagem na aws
      await this.update(orientadorId, { imagem: signedUrl.split("?")[0] });
      return response.body;
    } catch (error) {
      throw new Error("Falha ao enviar imagem");
    }
  }

  static async getServicos(orientadorId: number): Promise<ServicoExtended[]> {
    console.debug('getServicos', orientadorId)
    try {
      const response = await http.request<ServicoExtended[]>({
        url: `/orientador/servico/${orientadorId}`,
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao adicionar servico ao orientador')
    }
  }

  static async adicionarServico (orientadorId: number, servicoId: number, preco: number): Promise<void> {
    console.debug('adicionarServico', orientadorId, servicoId, preco)
    try {
      await http.request({
        url: '/orientador/servico',
        method: 'POST',
        body: { orientadorId, servicoId, preco }
      })
    } catch (error) {
      throw new Error('Falha ao adicionar servico ao orientador')
    }
  }

  static async removerServico (orientadorId: number, servicoId: number): Promise<void> {
    console.debug('removerServico', orientadorId, servicoId)
    try {
      await http.request({
        url: '/orientador/servico',
        method: 'DELETE',
        body: { orientadorId, servicoId }
      })
    } catch (error) {
      throw new Error('Falha ao remover servico do orientador')
    }
  }

  static async atualizarServico (orientadorId: number, servicoId: number, novoPreco: number): Promise<void> {
    console.debug('atualizarServico', orientadorId, servicoId, novoPreco)
    try {
      await http.request({
        url: '/orientador/servico',
        method: 'PUT',
        body: { orientadorId, servicoId, novoPreco }
      })
    } catch (error) {
      throw new Error('Falha ao atualizar preco do servico do orientador')
    }
  }
}
