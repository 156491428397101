import React, { createContext, useContext, useState, ReactNode } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type BackdropContextType = {
  openBackdrop: () => void;
  closeBackdrop: () => void;
};

const defaultBackdropContext: BackdropContextType = {
  /**
    @deprecated
  */
  openBackdrop: () => {}, // Função vazia como placeholder
  closeBackdrop: () => {}
};

const BackdropContext = createContext<BackdropContextType>(defaultBackdropContext);

type BackdropProviderProps = {
  children: ReactNode;
};

// Provedor do contexto
export const BackdropProvider: React.FC<BackdropProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const openBackdrop = () => setOpen(true);
  const closeBackdrop = () => setOpen(false);

  return (
    <BackdropContext.Provider value={{ openBackdrop, closeBackdrop }}>
      <Backdrop
        open={open}
        onClick={closeBackdrop} // Você pode remover isso se não quiser que o backdrop feche ao clicar
        sx={{ color: '#fff', zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
};

// Hook para usar o backdrop
export const useBackdrop = () => useContext(BackdropContext);
