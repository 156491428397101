import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Orientador } from '../../models/orientador';
import { OrientadoresService } from '../../services/orientadores.service';
import moment from 'moment';
import { AgendamentosService } from '../../services/agendamentos.service';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

export type DiaBloqueado = {
  dia: Date;
  observacao: string;
  orientadorId?: number;
  hora?: string;
};

type Props = {
  onSave: (data: DiaBloqueado) => void;
  onClose: () => void;
};

const DiaBloqueadoFormModal: React.FC<Props> = ({ onClose, onSave }: Props) => {
  const [orientadores, setOrientadores] = useState<Orientador[]>([]);
  const [horarios, setHorarios] = useState([]);

  const fetchOrientadores = useCallback(async () => {
    try {
      const response = await OrientadoresService.getAll();
      setOrientadores(response);
    } catch (error) {
      console.error('Error fetching orientadores:', error);
    }
  }, []);

  const fetchHorarios = useCallback(async (dia: Date, orientadorId: number) => {
    if (dia && orientadorId) {
      const horarios = await AgendamentosService.getQuadroHorarios(
        orientadorId,
        moment(dia).toDate()
      );
      const horariosDisponiveis = horarios.filter((horario) => horario.disponivel);
      setHorarios(horariosDisponiveis);
    }
  }, []);

  useEffect(() => {
    fetchOrientadores();
  }, [fetchOrientadores]);

  const initialValues: DiaBloqueado = {
    dia: null,
    observacao: '',
    orientadorId: null,
    hora: '',
  };

  const validationSchema = Yup.object({
    dia: Yup.date().required('A data é obrigatória.'),
    observacao: Yup.string().required('A observação é obrigatória.'),
  });

  const handleSubmit = (values: DiaBloqueado, { setSubmitting }: any) => {
    setSubmitting(true);
    onSave(values);
    setSubmitting(false);
  };

  const ErrorMessage = ({ errors }) => (
    <span>{errors?.join(', ')}</span>
  );

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Adicionar um bloqueio de agenda</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você pode bloquear o dia inteiro, dia e hora para todos ou para apenas um orientador.
        </DialogContentText>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => (
            <Form>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="observacao"
                  name="observacao"
                  label="Observação"
                  value={values.observacao}
                  onChange={handleChange}
                  error={touched.observacao && Boolean(errors.observacao)}
                  helperText={touched.observacao && errors.observacao}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <DatePicker
                  label="Data que deseja Bloquear"
                  value={values.dia ? dayjs(values.dia) : null}
                  onChange={(value) => {
                    const date = value ? value.toDate() : null;
                    setFieldValue('dia', date);
                    if (date && values.orientadorId) {
                      fetchHorarios(date, values.orientadorId);
                    }
                  }}
                  minDate={dayjs()}
                  slotProps={{
                    textField: (params) => ({
                      ...params,
                      error: touched.dia && Boolean(errors.dia),
                      helperText: touched.dia && !!errors.dia,
                    }),
                    field: {
                      clearable: true,
                      onClear: () => setFieldValue('dia', null),
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="orientador-select-label">Orientador</InputLabel>
                <Select
                  labelId="orientador-select-label"
                  id="orientador-select"
                  name="orientadorId"
                  value={values.orientadorId || ''}
                  onChange={(event) => {
                    const selectedValue = event.target.value as number;
                    setFieldValue('orientadorId', selectedValue);
                    setFieldValue('hora', '');
                    if (values.dia) {
                      fetchHorarios(values.dia, selectedValue);
                    }
                  }}
                  label="Orientador"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Altura máxima do menu em pixels
                        overflow: 'auto', // Adiciona scroll automaticamente se necessário
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Nenhum</em>
                  </MenuItem>
                  {orientadores.map((orientador) => (
                    <MenuItem key={orientador.id} value={orientador.id}>
                      {orientador.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="horarios-label">Horários Disponíveis</InputLabel>
                <Select
                  labelId="horarios-label"
                  id="horario"
                  name="hora"
                  value={values.hora}
                  onChange={(event) => setFieldValue('hora', event.target.value as string)}
                  label="Horários Disponíveis"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250, // Altura máxima do menu em pixels
                        overflow: 'auto', // Adiciona scroll automaticamente se necessário
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Nenhum</em>
                  </MenuItem>
                  {horarios.map((horario, index) => (
                    <MenuItem key={index} value={horario.hora}>
                      {horario.hora.slice(0, 5)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Salvar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default DiaBloqueadoFormModal;
