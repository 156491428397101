import React, { useCallback, useEffect, useState } from 'react';
import { Button, Box, Switch, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HttpsIcon from '@mui/icons-material/Https';
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';

import UserFormModal from './UsuariosFormModal';
import { User } from '../../models/user';
import { UserService } from '../../services/users.service';
import moment from 'moment';
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'
import ConfirmationDialog from '../../components/ConfirmDialog'
import { ConfigService } from '../../services/config.service'
import ModalSenha from './ModalSenha'

const UsuariosDataGrid: React.FC = () => {
  const [rows, setRows] = useState<User[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSenhaOpen, setModalSenhaOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [openCofirmDelete, setOpenCofirmDelete] = useState(false);

  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const fetchRows = useCallback(async () => {
    openBackdrop();
    const rows = await UserService.getAll()
    setRows(rows);
    closeBackdrop();
  }, [])


  useEffect(() => {
    fetchRows()
  }, [fetchRows]);

  const handleSave = async (user: User) => {
    openBackdrop();
    try {
      if (user.id) {
        await UserService.update(user.id, user);
        openSnackbar('Usuário atualizado com sucesso', 'success');
      } else {
        await UserService.create(user);
        openSnackbar('Usuário criado com sucesso', 'success');
      }
      setModalOpen(false);
      await fetchRows();
    } catch (error) {
      console.error('Error saving user:', error);
      openSnackbar('Erro ao salvar usuário', 'error');
    }
    closeBackdrop();
  };

  const handleRemover = async (user: User) => {
    try {
      openBackdrop();
      await UserService.remove(user.id);
      await fetchRows();
      openSnackbar("Usuário removido com sucesso", "success");
    } catch (error) {
      console.error("Error removing serviço:", error);
      openSnackbar("Erro ao remover serviço", "error");
    }
    setOpenCofirmDelete(false)
    closeBackdrop();
  }

  const handleToggleStatus = async (userId: number, newStatus: boolean) => {
    try {
      openBackdrop();
      await UserService.update(userId, { status: newStatus });
      fetchRows();
      openSnackbar("Usuário atualizado com sucesso", "success");
    } catch (error) {
      console.error("Error updating usuário:", error);
      openSnackbar("Erro ao atualizar usuário", "error");
    }
    closeBackdrop();
  };

  const getTelefone = (user: User) => {
    if (!user?.phone || !user?.phone.area_code || !user?.phone.number)
      return "N/A";
    const { area_code, number } = user.phone;
    return `${area_code} ${number}`;
  };

  const handleSendWhatsapp = async (user: User) => {
    openBackdrop();
    const telefone = getTelefone(user);
    if (telefone === "N/A") return;
    const mensagem = "Olá, tudo bem?";
    const link = await ConfigService.makeLinkWhatsapp({ telefone, mensagem });
    window.open(link, "_blank");
    closeBackdrop();
  };

  const handleChangePassword = async (password: string) => {
    openBackdrop();
    try {
      await UserService.update(selectedUser?.id, { password });
      openSnackbar('Senha alterada com sucesso', 'success');
      setSelectedUser(null);
      setModalSenhaOpen(false);
    } catch (error) {
      console.error('Error changing password:', error);
      openSnackbar('Erro ao alterar senha', 'error');
    }
    closeBackdrop();
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'nome', headerName: 'Nome', width: 250, valueFormatter: (value, row) => row.nome + ' ' + row.sobrenome },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'dataCadastro', headerName: 'Data Cadastro', width: 150, type: 'date',
      valueGetter: (value) => value ? new Date(value) : null,
      valueFormatter: (value) => value ? moment(value as Date).format('DD/MM/YYYY') : undefined },
    {
      field: "status",
      headerName: "Ativo?",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <React.Fragment>
          <Switch
            checked={params.value}
            onChange={(event) => handleToggleStatus(params.row.id, event.target.checked)}
            color="primary"
          />
        </React.Fragment>
      ),
    },
    {
      field: 'actions',
      headerName: 'Opções',
      type: 'actions',
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedUser(params.row as User)
            setModalOpen(true)
          }}
          label="Editar"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover usuário">
              <DeleteIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedUser(params.row as User)
            setOpenCofirmDelete(true)
          }}
          label="Delete"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Mensagem no Whatsapp">
              <WhatsAppIcon />
            </Tooltip>
          }
          onClick={() => handleSendWhatsapp(params.row)}
          label="Mensagem no Whatsapp"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Alterar Senha">
              <HttpsIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedUser(params.row as User)
            setModalSenhaOpen(true)
          }}
          label="Mensagem no Whatsapp"
        />
      ],
    },
  ];

  return (
    <>
      <Button sx={{ m: 1 }} onClick={() =>  {
        setSelectedUser(null)
        setModalOpen(true)
      }}>
        Adicionar Novo Usuário
      </Button>
      <Box sx={{ display: "flex", mx: 1, mt: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onRowClick={(params) => setSelectedUser(params.row as User)}
          // loading={loading}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      {modalOpen && (
        <UserFormModal
          onSave={handleSave}
          onClose={() => setModalOpen(false)}
          user={selectedUser}
        />
      )}
      {modalSenhaOpen && (
        <ModalSenha
          handleChangePassword={handleChangePassword}
          onClose={() => setModalSenhaOpen(false)}
          user={selectedUser}
        />
      )}
      <ConfirmationDialog
        open={openCofirmDelete}
        onClose={() => setOpenCofirmDelete(false)}
        onConfirm={() => handleRemover(selectedUser)}
        title="Confirmar remoção"
        description="Tem certeza de que deseja remover este usuário? Esta ação é irreversível."
      />
    </>
  );
};

export default UsuariosDataGrid;
