import React, { useState } from 'react';
import { AppBar, Toolbar, Menu, MenuItem, Button, Box, IconButton, Drawer, useMediaQuery, Theme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

const Navbar: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorElCadastros, setAnchorElCadastros] = useState<null | HTMLElement>(null);
  const [anchorElConfig, setAnchorElConfig] = useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClose = () => {
    setAnchorElCadastros(null);
    setAnchorElConfig(null);
    setMobileOpen(false); // Close the mobile drawer on selection
  };

  const drawer = (
    <Box onClick={handleMenuClose} sx={{ textAlign: 'center' }}>
      <MenuItem component={RouterLink} to="/agendamentos">Agendamentos</MenuItem>
      <MenuItem component={RouterLink} to="/orientadores">Orientadores</MenuItem>
      <MenuItem component={RouterLink} to="/servicos">Serviços</MenuItem>
      <MenuItem component={RouterLink} to="/testemunhos">Testemunhos</MenuItem>
      <MenuItem component={RouterLink} to="/videos">Vídeos</MenuItem>
      <MenuItem component={RouterLink} to="/usuarios">Usuários</MenuItem>
      <MenuItem component={RouterLink} to="/infos">Sistema</MenuItem>
      <MenuItem component={RouterLink} to="/pagamentos">Pagamentos</MenuItem>
      {!isAuthenticated && <MenuItem component={RouterLink} to="/login">Login</MenuItem>}
      {isAuthenticated && <MenuItem onClick={logout}>Sair</MenuItem>}
    </Box>
  );

  // Event handlers for Cadastros dropdown
  const handleOpenCadastros = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCadastros(event.currentTarget);
  };

  const handleCloseCadastros = () => {
    setAnchorElCadastros(null);
  };

  // Event handlers for Configurações dropdown
  const handleOpenConfig = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElConfig(event.currentTarget);
  };

  const handleCloseConfig = () => {
    setAnchorElConfig(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <img src="/assets/logos/logo-tarot-branco.png" alt="Logo" style={{ height: 30, marginRight: 15 }} />
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img src="/assets/logos/logo-tarot-branco.png" alt="Logo" style={{ height: 30, marginRight: 15 }} />
          </Box>
        )}
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 } }}
        >
          {drawer}
        </Drawer>
        {!isMobile && (isAuthenticated ? (
          <>
            <Button color="inherit" component={RouterLink} to="/agendamentos">Agendamentos</Button>
            <Button
              color="inherit"
              aria-controls="cadastros-menu"
              aria-haspopup="true"
              endIcon={<ArrowDropDownIcon />}
              onClick={handleOpenCadastros}
            >
              Cadastros
            </Button>
            <Menu
              id="cadastros-menu"
              anchorEl={anchorElCadastros}
              keepMounted
              open={Boolean(anchorElCadastros)}
              onClose={handleCloseCadastros}
            >
              <MenuItem onClick={handleCloseCadastros} component={RouterLink} to="/orientadores">Orientadores</MenuItem>
              <MenuItem onClick={handleCloseCadastros} component={RouterLink} to="/servicos">Serviços</MenuItem>
              <MenuItem onClick={handleCloseCadastros} component={RouterLink} to="/produtos">Produtos</MenuItem>
              <MenuItem onClick={handleCloseCadastros} component={RouterLink} to="/testemunhos">Testemunhos</MenuItem>
              <MenuItem onClick={handleCloseCadastros} component={RouterLink} to="/videos">Vídeos</MenuItem>
              <MenuItem onClick={handleCloseCadastros} component={RouterLink} to="/usuarios">Usuários</MenuItem>
            </Menu>
            <Button
              color="inherit"
              aria-controls="config-menu"
              aria-haspopup="true"
              endIcon={<ArrowDropDownIcon />}
              onClick={handleOpenConfig}
            >
              Configurações
            </Button>
            <Menu
              id="config-menu"
              anchorEl={anchorElConfig}
              keepMounted
              open={Boolean(anchorElConfig)}
              onClose={handleCloseConfig}
            >
              <MenuItem onClick={handleCloseConfig} component={RouterLink} to="/infos">Sistema</MenuItem>
              <MenuItem onClick={handleCloseConfig} component={RouterLink} to="/historico">Histórico</MenuItem>
              <MenuItem onClick={handleCloseConfig} component={RouterLink} to="/pagamentos">Pagamentos</MenuItem>
            </Menu>
            <Button color="inherit" onClick={logout}>Sair</Button>
          </>
        ) : (
          <Button color="inherit" component={RouterLink} to="/login">Login</Button>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
