import { YoutubeVideo } from '../models/videos'
import { http } from './client'

export class VideosService {
  static async getAll() {
    try {
      const response = await http.request({
        url: '/videos',
        method: 'GET'
      })
      return response.body
    } catch (error) {
      throw new Error('Falha ao buscar usuários')
    }
  }

  static update(id: number, data: Partial<YoutubeVideo>) {
    return http.request({
      url: `/videos/${id}`,
      method: 'PUT',
      body: data
    })
  }

  static create(data: YoutubeVideo) {
    return http.request({
      url: '/videos',
      method: 'POST',
      body: data
    })
  }

  static remove(id: number) {
    return http.request({
      url: `/videos/${id}`,
      method: 'DELETE'
    })
  }
}
