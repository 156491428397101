import React, { useRef } from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  Paper,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import { Servico } from "../../models/servico";
import { useFormik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'

interface ServicoModalProps {
  onSave: (data: FormData, servicoId?: number) => void;
  servico?: Servico;
  onClose: () => void;
}

const ServicosFormModal: React.FC<ServicoModalProps> = ({
  servico,
  onClose,
  onSave,
}) => {
  const fileInputRef = useRef(null);
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const formik = useFormik<Servico & { permitirConsulta: boolean }>({
    initialValues: {
      id: servico?.id ?? null,
      nome: servico?.nome ?? "",
      descricao: servico?.descricao ?? "",
      capa: servico?.capa ?? "",
      status: servico?.status ?? true,
      introducao: servico?.introducao ?? "",
      permitirConsulta: servico?.action ? true : false,
      action: servico?.action ?? null,
    },
    validationSchema: Yup.object({
      nome: Yup.string().required("Nome é obrigatório"),
      descricao: Yup.string().required("Descrição é obrigatória"),
      capa: Yup.string().required("Capa é obrigatória"),
      introducao: Yup.string().required("Introdução é obrigatória"),
      permitirConsulta: Yup.boolean().optional(),
    }),
    onSubmit: (values) => {
      openBackdrop();
      try {
        const data = new FormData();
        data.append("nome", values.nome);
        data.append("descricao", values.descricao);
        data.append("introducao", values.introducao);
        // action is a mysql JSON filed, so we need to stringify it
        if (values.action) {
          data.append(
            "actionJson",
            new Blob([JSON.stringify(values.action)], {
              type: "application/json",
            })
          );
        }
        if (fileInputRef.current.files[0]) {
          data.append("file", fileInputRef.current.files[0]);
        }
        onSave(data, values?.id);
        onClose();
      } catch (error) {
        openSnackbar('Erro ao salvar serviço', 'error')
      }
    },
  });

  const handleChangePermitirConsulta = (event: any) => {
    formik.setFieldValue("permitirConsulta", event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("action", {
        routerLink: ["/orientadores", { service: formik.values.id }],
        texto: "Agendar Consulta",
      });
    } else {
      formik.setFieldValue("action", null);
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {servico?.nome ? "Editar Serviço" : "Novo Serviço"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Nome"
              name="nome"
              value={formik.values.nome}
              onChange={formik.handleChange}
              error={formik.touched.nome && Boolean(formik.errors.nome)}
              helperText={formik.touched.nome && formik.errors.nome}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              rows={4}
              label="Introdução"
              name="introducao"
              value={formik.values.introducao}
              onChange={formik.handleChange}
              error={
                formik.touched.introducao && Boolean(formik.errors.introducao)
              }
              helperText={formik.touched.introducao && formik.errors.introducao}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              rows={4}
              label="Descrição"
              name="descricao"
              value={formik.values.descricao}
              onChange={formik.handleChange}
              error={
                formik.touched.descricao && Boolean(formik.errors.descricao)
              }
              helperText={formik.touched.descricao && formik.errors.descricao}
            />
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(formik.touched.capa && formik.errors.capa)}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              name="capa"
              multiple={false}
              onChange={(event) => {
                const files = event.target.files;
                if (files && files[0]) {
                  formik.setFieldTouched("capa", true); // Marcar como tocado
                  formik.setFieldValue("capa", files[0].name); // Atualizar o nome do arquivo no Formik
                }
              }}
            />
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                borderColor:
                  formik.touched.capa && formik.errors.capa
                    ? "error.main"
                    : "transparent",
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <InputBase
                value={formik.values.capa ? formik.values.capa : ""}
                disabled
                sx={{ ml: 1, flex: 1 }}
                placeholder="Selecione o arquivo..."
                inputProps={{ "aria-label": "Selecione o arquivo..." }}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="upload picture"
                onClick={() => fileInputRef.current?.click()}
              >
                <CloudUploadIcon />
              </IconButton>
            </Paper>
            {formik.touched.capa && formik.errors.capa && (
              <Typography color="error" variant="caption">
                {formik.errors.capa}
              </Typography>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.permitirConsulta}
                  onChange={handleChangePermitirConsulta}
                  name="permitirConsulta"
                  color="primary"
                />
              }
              label="Permitir Consulta"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
          <Button type="submit">Salvar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ServicosFormModal;
