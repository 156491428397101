import { Button, Tooltip } from '@mui/material'
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import { ptBR } from '@mui/x-data-grid/locales'
import React, { useCallback, useEffect, useState } from 'react'
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'
import { ConfigService } from '../../services/config.service'
import { DiaBloqueado } from '../../models/dia-bloqueado'
import moment from 'moment'
import DiaBloqueadoFormModal from './DiaBloqueadoFormModal'
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationDialog from '../../components/ConfirmDialog'


type Props = {}

const DiasBloquaedos: React.FC<Props> = (props: Props) => {
  const [selected, setSelected] = useState<DiaBloqueado | null>(null);
  const [openCofirmRemover, setOpenCofirmRemover] = useState(false);
  const [rows, setRows] = useState<DiaBloqueado[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const fetchRows = useCallback(async () => {
    try {
      openBackdrop();
      const response = await ConfigService.getDiasBloqueados() ?? [];
      setRows(response);
    } catch (error) {
      openSnackbar("Erro ao buscar os dias bloqueados", "error");
      console.error("Error fetching videos:", error);
    }
    closeBackdrop();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRows();
  }, [fetchRows]);

  const handleRemover = async (data: DiaBloqueado) => {
    try {
      openBackdrop();
      await ConfigService.removeDiaBloqueado(data.id);
      openSnackbar("Dia bloqueado cancelado com sucesso", "success");
      setOpenCofirmRemover(false);
      fetchRows();
    } catch (error) {
      openSnackbar("Erro ao cancelar o dia bloqueado", "error");
      console.error("Error canceling dia bloqueado:", error);
    }
    closeBackdrop();
  }

  const handleSalvar = async (data: DiaBloqueado) => {
    try {
      openBackdrop();
      await ConfigService.addDiaBloqueado(data);
      openSnackbar("Dia bloqueado salvo com sucesso", "success");
      setModalOpen(false);
      fetchRows();
    } catch (error) {
      openSnackbar("Erro ao salvar o dia bloqueado", "error");
      console.error("Error saving dia bloqueado:", error);
    }
    closeBackdrop();
  }

  const columns: GridColDef<DiaBloqueado>[] = [
    { field: "observacao", headerName: "Observação", width: 350 },
    {
      field: "dia",
      headerName: "Dia",
      width: 150,
      valueFormatter: (value) => {
        return moment(value).utc().format("DD/MM/YYYY")
      }
    },
    {
      field: "hora",
      headerName: "Hora",
      width: 120,
      valueFormatter: (value: string) => value?.slice(0, 5)
    },
    { field: "orientadorNome", headerName: "Orientador", width: 200 },
    {
      field: "actions",
      headerName: "Opções",
      type: "actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Cancelar">
              <CancelIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelected(params.row);
            setOpenCofirmRemover(true)
          }}
          label="Cancelar"
        />,
      ],
    }
  ]
  return (
    <>
      <Button onClick={() => setModalOpen(true)} sx={{ m: 1 }}>
        Adicionar dia bloqueado
      </Button>
      <DataGrid
        autoHeight={true}
        rows={rows}
        columns={columns}
        onRowClick={(params) => setSelected(params.row as DiaBloqueado)}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      />
      {modalOpen && (
        <DiaBloqueadoFormModal
          onClose={() => setModalOpen(false)}
          onSave={handleSalvar}
        />
      )}
      <ConfirmationDialog
        open={openCofirmRemover}
        onClose={() => setOpenCofirmRemover(false)}
        onConfirm={() => handleRemover(selected)}
        title="Confirmar Remoção"
        description="Tem certeza de que deseja remover este bloqueio? esta ação não pode ser desfeita."
      />
    </>
  )
}

export default DiasBloquaedos
