import React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'
import { Testimonial } from '../../models/testimonials'

interface TestemunhoProps {
  onSave: (data: Testimonial) => void;
  testimonial?: Testimonial;
  onClose: () => void;
}

const TestemunhoModal: React.FC<TestemunhoProps> = ({
  testimonial,
  onClose,
  onSave,
}) => {
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const formik = useFormik<Testimonial>({
    initialValues: {
      id: testimonial?.id ?? null,
      name: testimonial?.name ?? "",
      text: testimonial?.text ?? "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
      text: Yup.string().required("Testemunho é obrigatório"),
    }),
    onSubmit: (values) => {
      openBackdrop();
      try {
        onSave(values);
        onClose();
      } catch (error) {
        openSnackbar('Erro ao salvar serviço', 'error');
      }
      closeBackdrop();
    },
  });

  return (
    <Dialog open={true} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {testimonial?.id ? "Editar Testemunho" : "Novo Testemunho"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Nome"
              name="name" // Alterado de "nome" para "name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              rows={6}
              label="Testemunho"
              name="text" // Alterado de "testemunho" para "text"
              value={formik.values.text}
              onChange={formik.handleChange}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
          <Button type="submit">Salvar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TestemunhoModal;
