import { DiaBloqueado } from '../models/dia-bloqueado'
import { Infos } from "../models/infos";
import { http, httpBackend } from "./client";

type MakeLinkWhatsappParams = {
  telefone?: string;
  mensagem?: string;
};

export class ConfigService {
  static async getInfos(): Promise<Infos> {
    return httpBackend
      .request({
        url: "/infos",
        method: "GET",
      })
      .then((response) => response.body);
  }

  static async update(infos: Infos): Promise<boolean> {
    return http
      .request<boolean>({
        url: "/infos",
        method: "PUT",
        body: infos,
      })
      .then((response) => response.body);
  }

  static async makeLinkWhatsapp(data: MakeLinkWhatsappParams): Promise<string> {
    const infos = await this.getInfos();
    const formatedMessage = encodeURIComponent(
      data.mensagem || infos.whatsappMessage,
    );
    const telefone = this.formatarTelefone(
      `+55 ${data.telefone || infos.telefone}`,
    );
    return `https://wa.me/${telefone}?text=${formatedMessage}`;
  }

  static formatarTelefone(telefone: string): string {
    return telefone.replace(/\D/g, "");
  }

  static convertToEmbededLink(url: string): string {
    try {
      const urlObj = new URL(url);
      const videoId = urlObj.searchParams.get("v");

      if (videoId) return `https://www.youtube.com/embed/${videoId}`;
      return url;
    } catch {
      return url;
    }
  }

  static async uploadImage(file: File): Promise<string> {
    const signedUrl = await this.generateSignedUrl(file.name);
    return httpBackend
      .request({
        url: signedUrl,
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      })
      .then((response) => response.body);
  }

  static async generateSignedUrl(filename: string): Promise<string> {
    return http
      .request<string>({
        url: "/admin/generate-signed-url",
        method: "POST",
        body: { filename },
      })
      .then((response) => response.body);
  }

  static async getDiasBloqueados(): Promise<DiaBloqueado[]> {
    return http
      .request<DiaBloqueado[]>({
        url: `/admin/dia-bloqueado`,
        method: "GET",
      })
      .then((response) => response.body);
  }

  static async addDiaBloqueado(data: DiaBloqueado): Promise<boolean> {
    return http
      .request<boolean>({
        url: "/admin/dia-bloqueado",
        method: "POST",
        body: data,
      })
      .then((response) => response.body);
  }

  static async removeDiaBloqueado(id: number): Promise<boolean> {
    return http
      .request<boolean>({
        url: `/admin/dia-bloqueado/${id}`,
        method: "DELETE",
      })
      .then((response) => response.body);
  }
}
