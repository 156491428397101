import { InternalAxiosRequestConfig, AxiosResponse } from 'axios'

export type HttpRequest<T = any> = {
  url: string;
  method: HttpMethod;
  body?: T;
  params?: any;
  headers?: any;
  responseType?: string;
  responseEncoding?: string;
  timeout?: number;
};

export type HttpMethod = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';

export enum HttpStatusCode {
  ok = 200,
  created = 201,
  accepted = 202,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  timeout = 408,
  preconditionFailed = 412,
  unprocessableEntity = 422,
  serverError = 500,
}

export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode;
  body?: T;
  headers?: any;
  statusText?: any;
};

export type RequestInterceptor = (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig
export type ResponseInterceptor = (response: AxiosResponse) => AxiosResponse
