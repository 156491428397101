import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import moment from "moment";

import SearchIcon from "@mui/icons-material/Search";

import { Paginated } from "../../models/paginated";
import { DatePicker } from "@mui/x-date-pickers";
import { useBackdrop } from "../../providers/Backdrop";
import { PagamentoService } from '../../services/pagamento.service'
import { Pagamento } from '../../models/pagamentos'

const PagamentosDataGrid: React.FC = () => {
  const [rows, setRows] = useState<Pagamento[]>([]);
  const [paging, setPaging] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [order, setOrder] = useState<Paginated["order"]>([]);
  const [dataPagamento, setDataPagamento] = useState(null);

  const clearHorarioInicioFiltro = () => setDataPagamento(null);

  const { openBackdrop, closeBackdrop } = useBackdrop();

  const fetchRows = useCallback(async () => {
    openBackdrop();
    try {
      const response = await PagamentoService.getPagamentos({
        page: paging.page,
        pageSize: paging.pageSize,
        filters: { dataPagamento },
        order,
      });
      setRows(response.data);
      setRowCount(response.rowCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      closeBackdrop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, dataPagamento, order]); // Include debouncedQuery in dependencies

  // Consolidated useEffect
  useEffect(() => {
    fetchRows();
  }, [fetchRows]); // Only one useEffect that triggers fetchRows and fetchOrientadores

  const columns: GridColDef<Pagamento>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "dataPagamento",
      headerName: "Data Pagamento",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) =>
        `${moment(row.dataPagamento).format("DD/MM/YYYY HH:mm")}`,
    },
    {
      field: "paymentMethod",
      headerName: "Metodo de Pagamento",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        switch (value) {
          case "credit_card":
            return "Cartão de Crédito";
          case "boleto":
            return "Boleto";
          case "pix":
            return "Pix";
          default:
            return "Outro";
        }
      }
    },
    {
      field: "valor",
      headerName: "Valor pago",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value: number) => value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    {
      field: "updatedAt",
      headerName: "Atualizado em",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        return `${moment(value).format("DD/MM/YYYY HH:mm")}`;
      },
    },

  ];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Coluna em telas pequenas, linha em telas maiores
          alignItems: "center",
          justifyContent: "center",
          my: 1,
          mx: 1,
        }}
      >
        <FormControl
          fullWidth
          margin="normal"
          sx={{ mr: 2, mb: 2, width: { md: "30%" } }}
        >
          <DatePicker
            label="Data do Pagamento"
            value={dataPagamento}
            onChange={(value) => {
              console.log("onChangeData", value);
              setDataPagamento(value);
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => clearHorarioInicioFiltro(),
              },
            }}
          />
        </FormControl>
        {/* preciso de um incon button pra fazer fectch dos dados */}
        <IconButton
          sx={{ ml: 1 }}
          size="large"
          onClick={() => {
            console.log("onClickBuscar");
            fetchRows();
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", mx: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onPaginationModelChange={(params) => {
            console.log(params);
            setPaging({ page: params.page + 1, pageSize: params.pageSize }); // DataGrid page is 0-indexed
          }}
          onFilterModelChange={(params) => console.log(params)}
          onSortModelChange={(params) => setOrder(params as any)}
          pageSizeOptions={[10, 20, 50]}
          initialState={{
            pagination: {
              paginationModel: {
                page: paging.page,
                pageSize: paging.pageSize,
              },
            },
          }}
          rowCount={rowCount}
          // loading={loading}
          pagination
          paginationMode="server"
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </div>
  );
};

export default PagamentosDataGrid;
