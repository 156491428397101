import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { User } from '../../models/user';

import { TextMaskCustom } from '../../components/TextFieldMasked'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { cpfCnpjValido } from '../../helpers/valida-cpf-cnpj'

interface UsuariosFormModalProps {
  user?: User | null;
  onSave: (user: User) => void;
  onClose: () => void;
}

const UsuariosFormModal: React.FC<UsuariosFormModalProps> = ({ user, onSave, onClose }) => {
  const getPasswordValidationSchema = () => {
    if (!user) {  // Condição para novo registro
      return {
        password: Yup.string()
          .required('A senha é obrigatória')
          .min(8, 'A senha deve ter pelo menos 8 caracteres'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
          .required('A confirmação da senha é obrigatória'),
      };
    }
    return {};
  };

  const cnpjMask = "00.000.000/0000-00"
  const cpfMask = "000.000.000-00"

  const [mask, setMask] = React.useState(cpfMask);

  useEffect(() => {
    if (user?.identification?.type === 'cnpj') setMask(cnpjMask);
    else setMask(cpfMask);
  }, [user?.identification?.type]);

  const formik = useFormik<User & { passwordConfirm: string } >({
    initialValues: {
      id: user?.id ?? 0,
      nome: user?.nome ?? '',
      sobrenome: user?.sobrenome ?? '',
      email: user?.email ?? '',
      address: user?.address ?? { id: '', zip_code: '', street_name: '', street_number: 0, city: { name: '' } },
      phone: user?.phone ?? { area_code: '55', number: '' },
      identification: user?.identification ?? { type: '', number: '' },
      apelido: user?.apelido ?? '',
      dataNascimento: user?.dataNascimento ?? null,
      foto: user?.foto ?? '',
      sexo: user?.sexo ?? '',
      password: user?.password ?? '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object<User>({
      nome: Yup.string().required('Obrigatório'),
      sobrenome: Yup.string().required('Obrigatório'),
      email: Yup.string().email('E-mail inválido').required('Obrigatório'),
      sexo: Yup.string().required('Obrigatório'),
      phone: Yup.object().shape({
        area_code: Yup.string().required('Obrigatório'),
        number: Yup.string()
          .required('Obrigatório')
          .test(
            "is-complete",
            "O número de telefone está incompleto",
            value => {
              const size = value.replace(/[^0-9]/g, '').length;
              return size === 11 || size === 10;
            }
        ),
      }),
      identification: Yup.object().shape({
        type: Yup.string().required('Obrigatório'),
        number: Yup.string()
          .required('Obrigatório')
          .test(
            "is-complete",
            "O número de identificação está incompleto",
            value => {
              const size = value.replace(/[^0-9]/g, '').length;
              if (formik.values.identification.type === 'cpf') return size === 11;
              if (formik.values.identification.type === 'cnpj') return size === 14;
              return false;
            },
          ).test("is-valid", "Número de identificação inválido", value => cpfCnpjValido(value)),
      }),
      dataNascimento: Yup.date().required('Obrigatório'),
      ...getPasswordValidationSchema(),
    }),
    onSubmit: (values) => {
      onSave(values);
      onClose();
    },
  });

  return (
    <Dialog open={true} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{user ? 'Editar' : 'Novo Registro'}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <TextField
              autoFocus
              id="nome"
              name="nome"
              label="Nome"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.nome}
              onChange={formik.handleChange}
              error={formik.touched.nome && Boolean(formik.errors.nome)}
              helperText={formik.touched.nome && formik.errors.nome}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              id="sobrenome"
              name="sobrenome"
              label="Sobrenome"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.sobrenome}
              onChange={formik.handleChange}
              error={formik.touched.sobrenome && Boolean(formik.errors.sobrenome)}
              helperText={formik.touched.sobrenome && formik.errors.sobrenome}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              id="#email"
              name="#email"
              label="E-mail"
              type="email"
              fullWidth
              autoComplete="off"
              variant="outlined"
              value={formik.values.email}
              onChange={(event) => {
                formik.setFieldValue('email', event.target.value);
                formik.setFieldTouched('email', true);
              }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>
          {!user && (
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <FormControl fullWidth margin="dense">
              <TextField
                id="#password"
                name={`password-${Math.random()}`}
                label="Senha"
                type="password"
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                value={formik.values.password}
                onChange={(event) => {
                  formik.setFieldValue('password', event.target.value);
                  formik.setFieldTouched('password', true);
                }}
                onFocus={() => formik.setFieldTouched("password", true, false)} // Garante que o campo é marcado como tocado quando focado
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={(formik.touched.password && formik.errors.password) ? formik.errors.password : "A senha deve conter pelo menos 8 caracteres"}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                label="Confirme a Senha"
                type="password"
                fullWidth
                variant="outlined"
                value={formik.values.passwordConfirm}
                onChange={formik.handleChange}
                error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
              />
            </FormControl>
          </Stack>)}

          <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="identification-label">Documento</InputLabel>
              <Select
                labelId="identification-label"
                id="identification"
                name="identification.type"
                value={formik.values.identification?.type}
                label="Sexo"
                onChange={(event) => {
                  formik.setFieldValue('identification.type', event.target.value);
                  formik.setFieldValue('identification.number', '');
                  setMask(event.target.value === 'cpf' ? cpfMask : cnpjMask);
                }}
                error={formik.touched.identification?.type && Boolean(formik.errors.identification?.type)}
              >
                <MenuItem value="cpf">CPF</MenuItem>
                <MenuItem value="cnpj">CNPJ</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth error={formik.touched.identification?.number && Boolean(formik.errors.identification?.number)} margin="dense">
              <TextField
                  fullWidth
                  error={formik.touched.identification?.number && Boolean(formik.errors.identification?.number)}
                  label="Número"
                  variant="outlined"
                  value={formik.values.identification?.number}
                  onChange={formik.handleChange}
                  name="identification?.number"
                  id="numero"
                  InputProps={{
                    inputComponent: TextMaskCustom as any,
                    inputProps: {
                      mask: mask
                    },
                  }}
                  helperText={formik.touched.identification?.number && formik.errors.identification?.number ? formik.errors.identification?.number : ""}
                />
            </FormControl>
          </Stack>

          <FormControl fullWidth error={formik.touched.phone?.number && Boolean(formik.errors.phone?.number)} margin="dense">
            <TextField
              error={formik.touched.phone?.number && Boolean(formik.errors.phone?.number)}
              label="Telefone"
              variant="outlined"
              value={formik.values.phone?.number}
              onChange={formik.handleChange}
              name="phone.number"
              id="phone-number"
              InputProps={{
                inputComponent: TextMaskCustom as any,
                inputProps: {
                  mask: '(00) #0000-0000',  // Máscara do campo
                },
              }}
              helperText={formik.touched.phone?.number && formik.errors.phone?.number ? formik.errors.phone?.number : ""}
            />
          </FormControl>

          <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="sexo-label">Sexo</InputLabel>
              <Select
                labelId="sexo-label"
                id="sexo"
                name="sexo"
                value={formik.values.sexo}
                label="Sexo"
                onChange={formik.handleChange}
                error={formik.touched.sexo && Boolean(formik.errors.sexo)}
              >
                <MenuItem value="male">Masculino</MenuItem>
                <MenuItem value="female">Feminino</MenuItem>
                <MenuItem value="non-binary">Não-binário</MenuItem>
                <MenuItem value="prefer-not-to-say">Prefiro não dizer</MenuItem>
                <MenuItem value="other">Outro</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <DatePicker
                label="Data de Nascimento"
                value={formik.values.dataNascimento ? dayjs(formik.values.dataNascimento) : null}
                onChange={(value) => {
                  console.log("onChangeData", value);
                  formik.setFieldValue('dataNascimento', value);
                }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => formik.setFieldValue('dataNascimento', null),
                  },
                }}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
          <Button type="submit">Salvar</Button>
        </DialogActions>
      </form>
      {/* { JSON.stringify(formik.errors) } */}
    </Dialog>
  );
};

export default UsuariosFormModal;
