import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './app/auth/AuthContext'
import Navbar from './app/layout/Navbar'
import { NotFoundPage } from './app/pages/NotFound'
import { OrientadoresPage } from './app/pages/Orientadores'
import { ServicosPage } from './app/pages/Servicos'
import { TestemunhosPage } from './app/pages/Testemunhos'
import { VideosPage } from './app/pages/Videos'
import { UsuariosPage } from './app/pages/Usuarios'
import { Agendamentos } from './app/pages/Agendamentos'
import { ThemeProvider, createTheme } from '@mui/material'
import { SnackbarProvider } from './app/providers/SnackBarProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BackdropProvider } from './app/providers/Backdrop'
import { InfosPage } from './app/pages/Infos'
import { LoginPage } from './app/pages/Login'
import { ProdutosPage } from './app/pages/Produtos'
import { NotReady } from './app/pages/NotReady'
import { HistoricoPage } from './app/pages/Historico'
import { PagamentosPage } from './app/pages/Pagamentos'

// Wrapper para conteúdo de rota privada
const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate replace to="/login" />;
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App: React.FC = () => {
  return (
    <BackdropProvider>
      <SnackbarProvider>
        <Router>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <AuthProvider>
              <ThemeProvider theme={darkTheme}>
                <Navbar />
              </ThemeProvider>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/agendamentos" element={<RequireAuth><Agendamentos /></RequireAuth>} />
                  <Route path="/orientadores" element={<RequireAuth><OrientadoresPage /></RequireAuth>} />
                  <Route path="/servicos" element={<RequireAuth><ServicosPage /></RequireAuth>} />
                  <Route path="/produtos" element={<RequireAuth><ProdutosPage /></RequireAuth>} />
                  <Route path="/testemunhos" element={<RequireAuth><TestemunhosPage /></RequireAuth>} />
                  <Route path="/videos" element={<RequireAuth><VideosPage /></RequireAuth>} />
                  <Route path="/usuarios" element={<RequireAuth><UsuariosPage /></RequireAuth>} />
                  <Route path="/infos" element={<RequireAuth><InfosPage /></RequireAuth>} />
                  <Route path="/historico" element={<RequireAuth><HistoricoPage /></RequireAuth>} />
                  <Route path="/pagamentos" element={<RequireAuth><PagamentosPage /></RequireAuth>} />
                  <Route path="/" element={<Navigate replace to="/agendamentos" />} />
                  <Route path="*" element={<NotFoundPage />} /> {/* Rota Not Found */}
                </Routes>
            </AuthProvider>
          </LocalizationProvider>
        </Router>
      </SnackbarProvider>
    </BackdropProvider>
  );
};

export default App;
