import { eachDayOfInterval, endOfMonth, startOfMonth, format } from 'date-fns'
import { Agendamento, AgendamentoExtended } from '../models/agendamento'
import { Horario } from '../models/horario'
import { PagedData, Paginated } from '../models/paginated'
import { http } from './client'
import { ConfigService } from './config.service'
import moment from 'moment'


export class AgendamentosService {
  static async getAll(data: Paginated): Promise<PagedData<AgendamentoExtended>> {
    try {
      const response = await http.request<PagedData<AgendamentoExtended>>({
        url: '/admin/agendamentos',
        method: 'POST',
        body: data
      })
      return response.body
    } catch (error) {
      console.error('Falha ao buscar os agendamentos', error)
      throw new Error('Falha ao buscar os agendamentos')
    }
  }

  static async getHistorico(data: Paginated): Promise<PagedData<AgendamentoExtended>> {
    try {
      const response = await http.request<PagedData<AgendamentoExtended>>({
        url: '/admin/historico',
        method: 'POST',
        body: data
      })
      return response.body
    } catch (error) {
      console.error('Falha ao buscar os agendamentos', error)
      throw new Error('Falha ao buscar os agendamentos')
    }
  }

  static async finalizar(id: number): Promise<boolean> {
    try {
      const response = await http.request<Agendamento>({
        url: `/admin/agendamento/finalizar/${id}`,
        method: 'PUT'
      })
      if (response.body.status !== 'finished') {
        return false
      }
      return true
    } catch (error) {
      console.error('Falha ao finalizar o agendamento', error)
      return false
    }
  }

  static async cancelar(id: number): Promise<boolean> {
    try {
      const response = await http.request<Agendamento>({
        url: `/admin/agendamento/cancelar/${id}`,
        method: 'PUT'
      })
      if (response.body.status !== 'canceled') {
        return false
      }
      return true
    } catch (error) {
      console.error('Falha ao cancelar o agendamento', error)
      return false
    }
  }

  static async listarDiasDisponiveis(param: Date = new Date()) {
    // Determina o início e o fim do mês atual
    const inicioMes = startOfMonth(param)
    const fimMes = endOfMonth(param)

    // Gera um array com todos os dias do mês
    const todosDiasMes = eachDayOfInterval({ start: inicioMes, end: fimMes })

    const infos = await ConfigService.getInfos()

    // Identifica os índices dos dias da semana que não estão disponíveis para atendimento
    const diasNaoDisponiveis = infos.diasAtendimento.filter((dia) => !dia.ativo).map((dia) => dia.diaSemana)
    console.log('Dias não disponíveis:', diasNaoDisponiveis)

    // Filtra os dias do mês que correspondem aos dias da semana não disponíveis
    const diasBloqueados = todosDiasMes
      .filter((dia) => diasNaoDisponiveis.includes(dia.getDay()))
      .map((dia) => format(dia, 'yyyy-MM-dd')) // Formata cada dia para 'yyyy-MM-dd'

    return diasBloqueados
  }

  static async getDiasBloqueados(orientadorId: number, date: Date): Promise<string[]> {
    try {
      const response = await http.request<string[]>({
        url: `/agendamentos/bloqueados/${orientadorId}/${date.toISOString()}`,
        method: 'GET'
      })
      return response.body
    } catch (error) {
      console.error('Falha ao buscar os dias bloqueados', error)
      throw new Error('Falha ao buscar os dias bloqueados')
    }
  }

  static async getQuadroHorarios(orientadorId: number, date: Date): Promise<Horario[]> {
    try {
      const formatedDate = moment(date).format('YYYY-MM-DD')
      const response = await http.request<Horario[]>({
        url: `/agendamentos/quadro-horarios/${orientadorId}/${formatedDate}`,
        method: 'GET'
      })
      return response.body
    } catch (error) {
      console.error('Falha ao buscar o quadro de horários', error)
      throw new Error('Falha ao buscar o quadro de horários')
    }
  }

  static async reagendar(id: number, dataInicio: Date, horarioInicio: string): Promise<boolean> {
    try {
      const horarioFim = moment(dataInicio).set({
        hour: parseInt(horarioInicio.split(':')[0]),
        minute: parseInt(horarioInicio.split(':')[1]),
        second: 0
      })
      .add(30, 'minutes')
      .format('HH:mm:ss')
      const response = await http.request<Agendamento>({
        url: `/agendamentos/reagendar/${id}`,
        method: 'PATCH',
        body: { dataInicio, horarioInicio, horarioFim }
      })
      if (response.body.status !== 'rescheduled') {
        return false
      }
      return true
    } catch (error) {
      console.error('Falha ao reagendar o atendimento', error)
      return false
    }
  }

}
