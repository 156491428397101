import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Switch, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { Orientador } from "../../models/orientador";
import { OrientadoresService } from "../../services/orientadores.service";

import EditIcon from "@mui/icons-material/Edit";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { useBackdrop } from "../../providers/Backdrop";
import { useSnackbar } from "../../providers/SnackBarProvider";
import ModalOrientador from "./OrientadoresFormModal";

const GridOrientadores: React.FC = () => {
  const [selectedOrientador, setSelectedOrientador] = useState<Orientador | null>(null);
  const [rows, setRows] = useState<Orientador[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();

  const fetchRows = useCallback(async () => {
    try {
      openBackdrop();
      const response = await OrientadoresService.getAll();
      setRows(response);
      closeBackdrop();
    } catch (error) {
      console.error("Error fetching orientadores:", error);
      openSnackbar("Erro ao buscar orientadores", "error");
      closeBackdrop();
    }
  }, []);

  useEffect(() => {
    fetchRows();
  }, [fetchRows]);

  const openModal = (orientador: Orientador) => {
    setSelectedOrientador(orientador);
    setModalOpen(true);
  };

  const handleToggleStatus = async (orientadorId: number, newStatus: boolean) => {
    try {
      openBackdrop();
      await OrientadoresService.update(orientadorId, { status: newStatus });
      fetchRows();
      openSnackbar("Orientador atualizado com sucesso", "success");
    } catch (error) {
      console.error("Error updating orientador:", error);
      openSnackbar("Erro ao atualizar orientador", "error");
    }
    closeBackdrop();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOrientador(null);
  };

  const handleSave = async (orientador: Orientador) => {
    try {
      openBackdrop();
      if (orientador?.id) {
        await OrientadoresService.update(orientador.id, orientador);
        fetchRows();
        openSnackbar("Orientador atualizado com sucesso", "success");
      } else {
        await OrientadoresService.create(orientador);
        fetchRows();
        openSnackbar("Orientador salvo com sucesso", "success");
      }
    } catch (error) {
      console.error("Error saving orientador:", error);
      openSnackbar("Erro ao salvar orientador", "error");
    }
    closeBackdrop();
    handleCloseModal();
  };

  const handleUploadImage = (orientadorId: number) => {
    fileInputRef.current?.click();
    fileInputRef.current?.addEventListener(
      "change",
      async (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (files && files[0]) {
          try {
            openBackdrop();
            const file = files[0];
            await OrientadoresService.uploadImage(file, orientadorId);
            openSnackbar("Imagem enviada com sucesso", "success");
          } catch (error) {
            console.error("Error uploading image:", error);
            openSnackbar("Erro ao enviar imagem", "error");
          }
          closeBackdrop();
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      },
      { once: true },
    );
  };

  const columns: GridColDef<Orientador>[] = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "nome", headerName: "Nome", width: 250 },
    { field: "consultasRealizadas", headerName: "Consultas", width: 100 },
    {
      field: "status",
      headerName: "Mostrar no site?",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <React.Fragment>
          <Switch
            checked={params.value}
            onChange={(event) => handleToggleStatus(params.row.id, event.target.checked)}
            color="primary"
          />
        </React.Fragment>
      ),
    },
    {
      field: "foto",
      headerName: "Foto",
      type: "actions",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Fazer upload da Foto">
              <AddAPhotoIcon />
            </Tooltip>
          }
          onClick={() => handleUploadImage(params.row.id as number)}
          label="Fazer upload de Imagem"
        />,
      ],
    },
    {
      field: "opcoes",
      headerName: "Opções",
      type: "actions",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => openModal(params.row as Orientador)}
          label="Editar"
        />,
      ],
    },
  ];

  const handleCellEdit = (params: any) => {
    console.log(params);
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
      />
      <Button onClick={() => openModal(null)} sx={{ m: 1 }}>
        Adicionar Novo Orientador
      </Button>
      <Box sx={{ display: "flex", mx: 1, mt: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onCellEditStop={handleCellEdit}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
        {modalOpen && (
          <ModalOrientador
            orientador={selectedOrientador}
            onSubmit={handleSave}
            onClose={handleCloseModal}
          />
        )}
      </Box>
    </>
  );
};

export default GridOrientadores;
