import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { HttpClient } from './http-client'
import { JwtPayload, jwtDecode } from 'jwt-decode'

const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'

export const httpBackend = new HttpClient({ baseURL: API_URL })

export const redirectInterceptor = (response: AxiosResponse) => {
  if (response.status === 401) {
    console.error('Não autorizado, redirecionando para login...');
    localStorage.removeItem('token');
    window.location.href = '/login';
  }
  return response;
};

const tokenInterceptor = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedToken = jwtDecode<JwtPayload>(token);
      if (decodedToken.exp && Date.now() >= decodedToken.exp * 1000) {
        console.error('Token expirado');
        window.location.href = '/'
      } else {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    } catch (error) {
      console.error('Erro ao decodificar o token', error);
    }
  }
  return config;
};

export const http = new HttpClient({
  baseURL: API_URL,
  withCredentials: true
}, tokenInterceptor, redirectInterceptor)

