import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Alert, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AgendamentoExtended } from '../../models/agendamento';
import { AgendamentosService } from '../../services/agendamentos.service';
import { useBackdrop } from '../../providers/Backdrop'
import moment from 'moment'

interface UserFormModalProps {
  data?: AgendamentoExtended | null;
  onSave: (agendamentoId: number, novaData: Date, novaHora: string) => void;
  onClose: () => void;
  bloquearDia?: boolean;
}

const ReagendarModal: React.FC<UserFormModalProps> = ({ data, onSave, onClose, bloquearDia }) => {
  const [dataInicio, setDataInicio] = useState<dayjs.Dayjs | null>(data?.dataInicio ? dayjs(data.dataInicio) : null);
  const [horarioInicio, setHorarioInicio] = useState<string | null>(null);
  const [orientadorId] = useState<number | null>(data?.orientadorId || null);
  const [horarios, setHorarios] = useState([]);
  const { openBackdrop, closeBackdrop } = useBackdrop();

  const fetchHorarios = useCallback(async () => {
    openBackdrop();
    if (dataInicio && orientadorId) {
      const horarios = await AgendamentosService.getQuadroHorarios(orientadorId, dataInicio.toDate());
      const horariosDisponiveis = horarios.filter(horario => horario.disponivel)
      setHorarios(horariosDisponiveis);
    }
    closeBackdrop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInicio, orientadorId]);

  useEffect(() => {
    if (dataInicio && orientadorId) {
      fetchHorarios();
    }
  }, [dataInicio, orientadorId, fetchHorarios]);

  const hadleChangeDate = (date: dayjs.Dayjs | null) => {
    setDataInicio(date);
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <Box>
        <DialogTitle>{data ? 'Editar Agendamento' : 'Novo Agendamento'}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Data do Agendamento"
              value={dataInicio}
              onChange={hadleChangeDate}
            />
          </FormControl>
          {!bloquearDia && (
            horarios.length > 0 ? (
              <FormControl fullWidth margin="normal">
                <InputLabel id="horarios-label">Horários Disponíveis</InputLabel>
                <Select
                  labelId="horarios-label"
                  id="horario"
                  name="horario"
                  value={horarioInicio}
                  onChange={(event) => setHorarioInicio(event.target.value)}
                  label="Horários Disponíveis"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,  // Altura máxima do menu em pixels
                        overflow: 'auto',  // Adiciona scroll automaticamente se necessário
                      },
                    },
                  }}
                >
                  {horarios.map((horario, index) => (
                    <MenuItem key={index} value={horario.hora}>{horario.hora.slice(0,5)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Alert severity="error">Nenhum horário disponível para este dia!!</Alert>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
          <Button type="button" onClick={() => onSave(data.id, dataInicio.toDate(), horarioInicio)} disabled={!dataInicio || !horarioInicio}>Salvar</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ReagendarModal;
