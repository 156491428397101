import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Switch, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { ServicosService } from "../../services/servicos.service";
import { Servico } from "../../models/servico";
import ServicosFormModal from "./ServicosFormModal";
import { useBackdrop } from '../../providers/Backdrop'
import { useSnackbar } from '../../providers/SnackBarProvider'
import ConfirmationDialog from '../../components/ConfirmDialog'

const ServicosDataGrid: React.FC = () => {
  const [selectedServico, setSelectedServico] = useState<Servico | null>(null);
  const [rows, setRows] = useState<Servico[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { openSnackbar } = useSnackbar();
  const [openCofirmation, setOpenCofirmation] = useState(false);

  const fetchServicos = useCallback(async () => {
    try {
      openBackdrop();
      const response = await ServicosService.getAll();
      setRows(response);
    } catch (error) {
      console.error("Error fetching servicos:", error);
    }
    closeBackdrop();
  }, []);

  useEffect(() => {
    fetchServicos();
  }, [fetchServicos]);

  const handleToggleStatus = async (event: any, servicoId: number) => {
    try {
      openBackdrop();
      await ServicosService.updateData(servicoId, { status: event.target.checked });
      await fetchServicos();
      openSnackbar("Servico atualizado com sucesso", "success");
    } catch (error) {
      console.error("Error updating serviço:", error);
      openSnackbar("Erro ao atualizar serviço", "error");
    }
    closeBackdrop();
  };

  const handleSave = async (data: FormData, servicoId?: number) => {
    openBackdrop();
    if (!servicoId) {
      await ServicosService.create(data);
      openSnackbar("Serviço criado com sucesso", "success");
    } else {
      await ServicosService.update(servicoId, data);
      openSnackbar("Serviço atualizado com sucesso", "success");
    }
    await fetchServicos();
    closeBackdrop();
  }

  const handleRemover = async (servico: Servico) => {
    try {
      openBackdrop();
      await ServicosService.remove(servico.id);
      await fetchServicos();
      openSnackbar("Serviço removido com sucesso", "success");
    } catch (error) {
      console.error("Error removing serviço:", error);
      openSnackbar("Erro ao remover serviço", "error");
    }
    setOpenCofirmation(false)
    closeBackdrop();
  }

  const columns: GridColDef<Servico>[] = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "nome", headerName: "Nome", width: 350 },
    {
      field: "status",
      headerName: "Mostrar no site?",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <React.Fragment>
          <Switch
            checked={params.value}
            onChange={(event) => handleToggleStatus(event, params.row.id)}
            color="primary"
          />
        </React.Fragment>
      ),
    },
    {
      field: "actions",
      headerName: "Opções",
      type: "actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedServico(params.row as Servico)
            setModalOpen(true)
          }}
          label="Editar"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover">
              <DeleteIcon />
            </Tooltip>
          }
          onClick={() => {
            setSelectedServico(params.row as Servico)
            setOpenCofirmation(true)
          }}
          label="Remover"
        />,
      ],
    },
  ];

  return (
    <>
      <Button sx={{ m: 1 }} onClick={() =>  {
        setSelectedServico(null)
        setModalOpen(true)
      }}>
        Adicionar Novo Serviço
      </Button>
      <Box sx={{ display: "flex", mx: 1, mt: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          onRowClick={(params) => setSelectedServico(params.row as Servico)}
          // loading={loading}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      {modalOpen && (
        <ServicosFormModal
          onSave={handleSave}
          onClose={() => setModalOpen(false)}
          servico={selectedServico}
        />
      )}
      <ConfirmationDialog
        open={openCofirmation}
        onClose={() => setOpenCofirmation(false)}
        onConfirm={() => handleRemover(selectedServico)}
        title="Confirmar remoção"
        description="Tem certeza de que deseja remover este serviço? Esta ação é irreversível."
      />
    </>
  );
};

export default ServicosDataGrid;
